<template>
    <Tabs value="0">
      <TabList>
        <Tab value="0">사용자</Tab>
        <Tab value="1">키워드</Tab>
      </TabList>
      <TabPanels>
        <TabPanel value="0">
          <div id="statistics" >
            <table class="table" style="width:100%; ">
              <tr style="font-weight: bold;">
                <td><span style="color: #ff7f50;">&#9654;</span> 사용자</td>
                <td>
                  <input style="max-width:50px;float:right; margin-right:10px;" type="button" v-on:click="click({type:'refreshUser'})" value="조회" />
                </td>
              </tr>
            </table>
            <br>
            <div class="card main" >
              <table class="table" style="width:100%;font-weight:lighter;">
                <tr>
                  <td style="width:10%" class="table-title">회원수</td>
                  <td style="width:22%;text-align:center;" class="table-value">{{ Number(allUser).toLocaleString() }}</td>
                  <td style="width:10%" class="table-title">순회원</td>
                  <td style="width:22%;text-align:center;" class="table-value">{{ Number(realUser).toLocaleString() }}</td>
                  <td style="width:10%" class="table-title">순회원비율</td>
                  <td style="width:22%;text-align:center;" class="table-value">{{ Number(realUser/allUser * 100).toFixed(2) }}%</td>
                </tr>
              </table>
              <br>
              <table class="table" style="width:100%;font-weight:lighter;">
                <tr>
                  <td style="width:20%" class="table-title">OS</td>
                  <td style="width:20%" class="table-value">Windows:{{ windows }}</td>
                  <td style="width:20%" class="table-value">Android:{{ android }}</td>
                  <td style="width:20%" class="table-value">iPhone:{{ iPhone }}</td>
                  <td style="width:20%" class="table-value">Mac:{{ Mac }}</td>
                </tr>
              </table>
            </div>
            <div class="card main" >
              <table class="table" style="width:100%;font-weight:lighter;text-align:center;">
                <tr>
                  <td colspan="7" class="table-title-row table-title">전체방문자</td>
                </tr>
                <tr>
                  <td class="table-value">{{ vv0 + uv0 }}</td>
                  <td class="table-value">{{ vv1 + uv1 }}</td>
                  <td class="table-value">{{ vv2 + uv2 }}</td>
                  <td class="table-value">{{ vv3 + uv3 }}</td>
                  <td class="table-value">{{ vv4 + uv4 }}</td>
                  <td class="table-value">{{ vv5 + uv5 }}</td>
                  <td class="table-value">{{ vv6 + uv6 }}</td>
                </tr>
              </table>
              <br>
              <table class="table" style="width:100%;font-weight:lighter;text-align:center;">  
                <tr>
                  <td colspan="7" class="table-title-row table-title">재방문자(DAU)</td>
                </tr>
                <tr>
                  <td class="table-value">{{ vv0 }}</td>
                  <td class="table-value">{{ vv1 }}</td>
                  <td class="table-value">{{ vv2 }}</td>
                  <td class="table-value">{{ vv3 }}</td>
                  <td class="table-value">{{ vv4 }}</td>
                  <td class="table-value">{{ vv5 }}</td>
                  <td class="table-value">{{ vv6 }}</td>
                </tr>
              </table>
              <br>
              <table class="table" style="width:100%;font-weight:lighter;text-align:center;">
                <tr>
                  <td colspan="7" class="table-title-row table-title">가입자</td>
                </tr>
                <tr>
                  <td class="table-value">{{ uv0 }}</td>
                  <td class="table-value">{{ uv1 }}</td>
                  <td class="table-value">{{ uv2 }}</td>
                  <td class="table-value">{{ uv3 }}</td>
                  <td class="table-value">{{ uv4 }}</td>
                  <td class="table-value">{{ uv5 }}</td>
                  <td class="table-value">{{ uv6 }}</td>
                </tr>      
                <tr>
                  <td>{{ d0 }}</td>
                  <td>{{ d1 }}</td>
                  <td>{{ d2 }}</td>
                  <td>{{ d3 }}</td>
                  <td>{{ d4 }}</td>
                  <td>{{ d5 }}</td>
                  <td>{{ d6 }}</td>
                </tr>
              </table>
            </div>
            <div class="card main" >
              <table class="table" style="width:100%;font-weight:lighter;text-align:center;">
                <tr>
                  <td colspan="7" class="table-title-row table-title">방문횟수</td>
                </tr>
                <tr v-if="visitCntList.length >= 7">
                  <td class="table-value">{{ visitCntList[0].cnt }}</td>
                  <td class="table-value">{{ visitCntList[1].cnt }}</td>
                  <td class="table-value">{{ visitCntList[2].cnt }}</td>
                  <td class="table-value">{{ visitCntList[3].cnt }}</td>
                  <td class="table-value">{{ visitCntList[4].cnt }}</td>
                  <td class="table-value">{{ visitCntList[5].cnt }}</td>
                  <td class="table-value">{{ visitCntList[6].cnt }}</td>
                </tr>      
                <tr v-if="visitCntList.length >= 7">
                  <td>{{ visitCntList[0].mail }}</td>
                  <td>{{ visitCntList[1].mail }}</td>
                  <td>{{ visitCntList[2].mail }}</td>
                  <td>{{ visitCntList[3].mail }}</td>
                  <td>{{ visitCntList[4].mail }}</td>
                  <td>{{ visitCntList[5].mail }}</td>
                  <td>{{ visitCntList[6].mail }}</td>
                </tr>      
              </table>
            </div>
            <br>
            <table class="table" style="width:100%; ">
              <tr style="font-weight: bold;">
                <td><span style="color: #ff7f50;">&#9654;</span> 활성사용자</td>
                <td>
                  <input style="max-width:50px;float:right; margin-right:10px;" type="button" v-on:click="click({type:'dau'})" value="조회" />
                  <label style="font-weight:lighter;float:right;margin-top:5px;margin-right:10px;">MAU:{{ recentMau }}</label>
                  <label style="font-weight:lighter;float:right;margin-top:5px;margin-right:10px;">WAU:{{ recentWau }},</label>
                </td>
              </tr>
            </table>
            <br>
            <div class="card main" >
              <table class="table" style="width:100%;font-weight:lighter;text-align:center;">
                <tr>
                  <td colspan="7" class="table-title-row table-title">DAU</td>
                </tr>
                <tr>
                  <td v-for="(dau, index) in dauList" :key="index" class="table-value">{{ dau.DAU }}</td>
                </tr>                
              </table>
              <br>
              <table class="table" style="width:100%;font-weight:lighter;text-align:center;">
                <tr>
                  <td colspan="7" class="table-title-row table-title">DAU / WAU</td>
                </tr>
                <tr>
                  <td v-for="(dauWau, index) in dauWauList" :key="index" class="table-value">{{ dauWau }}</td>
                </tr>
              </table>
              <br>
              <table class="table" style="width:100%;font-weight:lighter;text-align:center;">
                <tr>
                  <td colspan="7" class="table-title-row table-title">DAU / MAU</td>
                </tr>
                <tr>
                  <td v-for="(dauMau, index) in dauMauList" :key="index" class="table-value">{{ dauMau }}</td>
                </tr>
              </table>
              <table class="table" style="width:100%;font-weight:lighter;text-align:center;">
                <tr>
                  <td v-for="(dau, index) in dauList" :key="index" >{{ this.api.getDateByFormat(dau.REG_DATE, 'MM/DD') }}</td>
                </tr>  
              </table>
            </div>
            <br>
            <div class="card main" >
              <table class="table" style="width:100%;font-weight:lighter;text-align:center;">
                <tr>
                  <td colspan="7" class="table-title-row table-title">WAU</td>
                </tr>
                <tr>
                  <td v-for="(wau, index) in wauList" :key="index" class="table-value">{{ wau.WAU }}</td>
                </tr>      
                <tr>
                  <td v-for="(wau, index) in wauList" :key="index" >
                    <label v-if="index === (6-0)">이번주</label> 
                    <label v-else>W-{{ (6-index) }}</label> 
                  </td>
                </tr>      
              </table>
            </div>
            <br>
            <div class="card main" >
              <table class="table" style="width:100%;font-weight:lighter;text-align:center;">
                <tr>
                  <td colspan="7" class="table-title-row table-title">MAU</td>
                </tr>
                <tr>
                  <td v-for="(mau, index) in mauList.slice().reverse()" :key="index" class="table-value">{{ mau.MAU }}</td>
                </tr>      
                <tr>
                  <td v-for="(mau, index) in mauList.slice().reverse()" :key="index" >
                    <label v-if="index === (6-0)">이번달</label> 
                    <label v-else>M-{{ (6-index) }}</label>   
                  </td>
                </tr>      
              </table>
            </div>
            <br>
            <table class="table" style="width:100%; ">
              <tr style="font-weight: bold;">
                <td><span style="color: #ff7f50;">&#9654;</span> 주문</td>
                <td>
                  <input style="max-width:50px;float:right; margin-right:10px;" type="button" v-on:click="click({type:'refreshOrder'})" value="조회" />
                </td>
              </tr>
            </table>
            <br>
            <div class="card main" >
              <table class="table" style="width:100%;font-weight:lighter;">
                <tr>
                  <td style="width:25%" class="table-title">구매대행</td>
                  <td style="width:25%;text-align:left;" class="table-value">{{ sv0.toLocaleString() }}건 ({{ sv2 }}%)</td>
                  <td style="width:25%" class="table-title">배송대행</td>
                  <td style="width:25%;text-align:left;" class="table-value">{{ sv1.toLocaleString() }}건 ({{ (100-sv2).toFixed(2) }}%)</td>
                </tr>
              </table>
            </div>
            <br>
            <div class="card main" >
              <table class="table" style="width:100%;font-weight:lighter;">
                <tr>
                  <td colspan="6" class="table-title-row table-title">배대지</td>
                </tr>
                <tr><td style="height:5px;"></td></tr>
                <tr>
                  <td style="font-size: 5px;" class="table-value">솔에어<br>{{ sv3.toLocaleString() }}건<br>{{ sv9 }}%</td>
                  <td style="font-size: 5px;" class="table-value">엘덱스<br>{{ sv5.toLocaleString() }}건<br>{{ sv11 }}%</td>
                  <td style="font-size: 5px;" class="table-value">아이포터<br>{{ sv4.toLocaleString() }}건<br>{{ sv10 }}%</td>
                  <td style="font-size: 5px;" class="table-value">WEL<br>{{ sv6 }}건<br>{{ sv12 }}%</td>
                  <td style="font-size: 5px;" class="table-value">포스트고<br>{{ sv7 }}건<br>{{ sv13 }}%</td>
                  <td style="font-size: 5px;" class="table-value">기타<br>{{ sv8 }}건<br>{{ sv14 }}%</td>
                </tr>
              </table>
            </div>
            <br>
            <div class="card main" >
              <table class="table" style="width:100%;font-weight:lighter;">
                <tr>
                  <td colspan="6" class="table-title-row table-title">대행지</td>
                </tr>
                <tr><td style="height:5px;"></td></tr>
                <tr>
                  <td style="font-size: 5px;" class="table-value">홍콩<br>{{ sv15.toLocaleString() }}건<br>{{ sv26 }}%</td>
                  <td style="font-size: 5px;" class="table-value">프랑스<br>{{ sv16.toLocaleString() }}건<br>{{ sv27 }}%</td>
                  <td style="font-size: 5px;" class="table-value">스페인<br>{{ sv18 }}건<br>{{ sv29 }}%</td>
                  <td style="font-size: 5px;" class="table-value">미국<br>{{ sv23.toLocaleString() }}건<br>{{ sv34 }}%</td>
                  <td style="font-size: 5px;" class="table-value">영국<br>{{ sv20 }}건<br>{{ sv31 }}%</td>
                  <td style="font-size: 5px;" class="table-value">독일<br>{{ sv24 }}건<br>{{ sv35 }}%</td>
                </tr>
                <tr><td style="height:5px;"></td></tr>
                <tr>
                  <td style="font-size: 5px;" class="table-value">이탈리아<br>{{ sv19 }}건<br>{{ sv30 }}%</td>
                  <td style="font-size: 5px;" class="table-value">중국<br>{{ sv22.toLocaleString() }}건<br>{{ sv33 }}%</td>
                  <td style="font-size: 5px;" class="table-value">일본<br>{{ sv21.toLocaleString() }}건<br>{{ sv32 }}%</td>
                  <td style="font-size: 5px;" class="table-value">싱가포르<br>{{ sv17.toLocaleString() }}건<br>{{ sv28 }}%</td>
                  <td style="font-size: 5px;" class="table-value">기타<br>{{ sv25 }}건<br>{{ sv36 }}%</td>
                  <td style="font-size: 5px;" class="table-value"></td>
                </tr>
              </table>
            </div>
          </div>
        </TabPanel>  
        <TabPanel value="1">
          <div id="statistics" >
            <div class="div_title2">
              <span>
                <label style="color: #ff7f50;">&#9654;</label> 키워드
              </span>
              <span>
                <Select v-model="keywordRange" :options="keywordRangeList" optionLabel="val" placeholder="기간"  />&nbsp;
                <Button  size="small" @click="click({type:'KEYWORD'})" label="조회" />
              </span>
            </div>
            <br>
            <div class="card main" >
              <div>
                <table style="width:100%;">
                  <tr v-for="(keyword, index) in keywords" :key="index" style="height: 30px">
                    <td :style="index%2 === 0 ? 'background-color: #f9f9f9':''" >
                      <div class="div_center order-div">
                        <div style="width:100%;">{{ keyword.KEYWORD }}</div>
                        <div style="max-width:100px;">{{ keyword.CNT }}</div>
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </TabPanel>  
      </TabPanels>
    </Tabs>
  
</template>
<script>
import { ref } from 'vue'

export default {
  components: {
  },  
  mounted() {
  }, 
  setup() {
    const keywordRange = ref(null)
    const keywordRangeList = ref([{key:"WEEK_KEYWORD", val:'7일'}, {key:"MONTH_KEYWORD", val:'30일'}, {key:"YEAR_KEYWORD", val:'1년'}])
    return {
      keywordRange, 
      keywordRangeList
    }
    
  },
  data() { 
    return { 
      keywords:[],
      recentWau:-1,
      recentMau:-1,
      dauWauList: [],
      dauMauList: [],
      dauList: [],
      wauList: [],
      mauList: [],
      orderList: Array(),
      statisticList: Array(),
      historyList: Array(),
      visitCntList: Array(),
      allUser:0,
      realUser:0,
      d0:0, d1:0, d2:0, d3:0, d4:0, d5:0, d6:0,
      vv0:0, vv1:0, vv2:0, vv3:0, vv4:0, vv5:0, vv6:0,
      // rvv0:0, rvv1:0, rvv2:0, rvv3:0, rvv4:0, rvv5:0, rvv6:0,
      uv0:0, uv1:0, uv2:0, uv3:0, uv4:0, uv5:0, uv6:0,
      windows:0, android:0, iPhone:0, Mac:0,

      sv0:0,sv1:0,sv2:0.0,sv3:0,sv4:0,sv5:0,sv6:0,sv7:0,sv8:0,sv9:0,sv10:0,sv11:0,sv12:0,sv13:0,sv14:0,sv15:0,sv16:0,sv17:0,sv18:0,sv19:0,sv20:0,sv21:0,sv22:0,sv23:0,sv24:0,sv25:0
      ,sv26:0,sv27:0,sv28:0,sv29:0,sv30:0,sv31:0,sv32:0,sv33:0,sv34:0,sv35:0,sv36:0,sv37:0
    }
  },
  methods: {
    click(param) {
      const type = param.type 
      
      if(type === 'searchUser') {
        this.clearUser()
        if(this.statisticList.length === 0) {
          this.search({type:'SELECT_POINT_HISTORY'})
          this.search({type:'SELECT_MYPAGE'})
        } else this.process('user')
      } else if(type === 'refreshUser') {
        this.clearUser()
        this.search({type:'SELECT_POINT_HISTORY'})
        this.search({type:'SELECT_MYPAGE'})
      } else if(type === 'searchOrder') {
        this.clearOrder()
        if(this.orderList.length === 0) this.search({type:'SELECT_ORDER_STATISTIC'})
        else this.process('SELECT_ORDER_STATISTIC')
      } else if(type === 'refreshOrder') {
        this.clearOrder()
        this.search({type:'SELECT_ORDER_STATISTIC'})
      } else if(type === 'dau') this.search({type:type})
      else if(type === 'KEYWORD') {
        if(this.keywordRange === null) this.emitter.emit('confirm', {message: '기간을 선택해주세요.', header:'키워드', icon:'pi pi-exclamation-triangle'})
        else {
          const type = this.keywordRange.key
          this.api.axiosGql(type, {type:type})
          .then( async (res) => {
            this.keywords = res.data
          })
          .catch((err) => {
            console.log(err)
          })
        }
      }
    },
    async search(param) {
      const type = param.type 
      if(type === 'SELECT_MYPAGE') {
        this.api.axiosGql(type, {type:'SELECT_MYPAGE', id:undefined})
        .then( async (res) => {
          this.statisticList = res.data
          this.process('user')
        })
        .catch((err) => {
          console.log(err)
        })
      } else if(type === 'SELECT_POINT_HISTORY') {
        await this.api.axiosGql('SELECT_POINT_HISTORY', {
        type: 'SELECT_POINT_HISTORY', val:undefined, id:undefined, pointType:undefined, page:-3 })
        .then( async (res) => {
          this.historyList = res.data.data.getPointHistory
          this.process('pointHistory')
        })
        .catch((err) => {
          console.log(err)
        })
      } else if(type === 'SELECT_ORDER_STATISTIC') {
        await this.api.axiosGql(type, { type: type })
        .then( async (res) => {
          this.orderList = res.data
          this.process(type)
        })
        .catch((err) => {
          console.log(err)
        })
      } else if(type === 'dau') {
        await this.api.axiosGql(type, { type: type })
        .then( async (res) => {
          this.dauList = []
          this.dauList = res.data
          await this.api.axiosGql('recentWau', { type: 'recentWau' })
          .then( async (recentWau) => {
            this.recentWau = Number(recentWau.data[0].WAU)
          })
          this.dauMauList = []
          this.dauWauList = []
          await this.api.axiosGql('recentMau', { type: 'recentMau' })
          .then( async (recentMau) => {
            this.recentMau = Number(recentMau.data[0].MAU)
            this.dauList.map(item => {
              const dauMau = (item.DAU / this.recentMau * 100).toFixed(2) + '%'
              const dauWau = (item.DAU / this.recentWau * 100).toFixed(2) + '%'
              this.dauMauList.push(dauMau)
              this.dauWauList.push(dauWau)
            })
          })
        })
        .catch((err) => {
          console.log(err)
        })
        this.search({type:'wau'})
        this.search({type:'mau'})

      } else if(type === 'wau') {
        await this.api.axiosGql(type, { type: type })
        .then( async (res) => {
          this.wauList = []
          this.wauList = res.data 
        })
        .catch((err) => {
          console.log(err)
        })
      } else if(type === 'mau') {
        await this.api.axiosGql(type, { type: type })
        .then( async (res) => {
          this.mauList = []
          this.mauList = res.data
        })
        .catch((err) => {
          console.log(err)
        })
      }
    },
    async process(type) {
      const range = this.api.getRange(-6)
      range.format = 'YYYYMMDD'
      const rangeList = this.api.getDateRange(range)
      
      if(type === 'SELECT_ORDER_STATISTIC') {
        if(this.orderList !== null && this.orderList.length !== 0) {
          for(let i=0; i<this.orderList.length; i++) {
            const order = this.orderList[i]
            const orderType = order.ORDER_TYPE
            const deliveryForeignCom = order.DELIVERY_FOREIGN_COMPANY
            const country = order.COUNTRY
            
            if(orderType === '구매대행') this.sv0++     
            else if(orderType === '배송대행') this.sv1++

            if(deliveryForeignCom === '솔에어') this.sv3++
            else if(deliveryForeignCom === '아이포터') this.sv4++
            else if(deliveryForeignCom === '엘덱스') this.sv5++
            else if(deliveryForeignCom === '월드익스프레스') this.sv6++
            else if(deliveryForeignCom === '포스트고') this.sv7++
            else this.sv8++

            if(country === '홍콩') this.sv15++
            else if(country === '프랑스') this.sv16++
            else if(country === '싱가포르') this.sv17++
            else if(country === '스페인') this.sv18++
            else if(country === '이탈리아') this.sv19++
            else if(country === '영국') this.sv20++
            else if(country === '일본') this.sv21++
            else if(country === '중국') this.sv22++
            else if(country === '미국') this.sv23++
            else if(country === '독일') this.sv24++
            else this.sv25++
          }
          this.sv2 = (this.sv0 / (this.sv0 + this.sv1) * 100).toFixed(2)
          
          const dividerFrCom = this.sv3 + this.sv4 + this.sv5 + this.sv6 + this.sv7 + this.sv8
          this.sv9  = (this.sv3 / dividerFrCom * 100).toFixed(2)
          this.sv10 = (this.sv4 / dividerFrCom * 100).toFixed(2)
          this.sv11 = (this.sv5 / dividerFrCom * 100).toFixed(2)
          this.sv12 = (this.sv6 / dividerFrCom * 100).toFixed(2)
          this.sv13 = (this.sv7 / dividerFrCom * 100).toFixed(2)
          this.sv14 = (this.sv8 / dividerFrCom * 100).toFixed(2)

          const dividerCountry = this.sv15 + this.sv16 + this.sv17 + this.sv18 + this.sv19 + this.sv20 + this.sv21 + this.sv22 + this.sv23 + this.sv24 + this.sv25
          this.sv26 = (this.sv15 / dividerCountry * 100).toFixed(2)
          this.sv27 = (this.sv16 / dividerCountry * 100).toFixed(2)
          this.sv28 = (this.sv17 / dividerCountry * 100).toFixed(2)
          this.sv29 = (this.sv18 / dividerCountry * 100).toFixed(2)
          this.sv30 = (this.sv19 / dividerCountry * 100).toFixed(2)
          this.sv31 = (this.sv20 / dividerCountry * 100).toFixed(2)
          this.sv32 = (this.sv21 / dividerCountry * 100).toFixed(2)
          this.sv33 = (this.sv22 / dividerCountry * 100).toFixed(2)
          this.sv34 = (this.sv23 / dividerCountry * 100).toFixed(2)
          this.sv35 = (this.sv24 / dividerCountry * 100).toFixed(2)
          this.sv36 = (this.sv25 / dividerCountry * 100).toFixed(2)
        }
      } else if(type === 'user') {
        if(this.statisticList !== null) {
          this.allUser = this.statisticList.length
          
          this.d0 = this.api.getDateByFormat(rangeList[0], 'MM/DD')
          this.d1 = this.api.getDateByFormat(rangeList[1], 'MM/DD')
          this.d2 = this.api.getDateByFormat(rangeList[2], 'MM/DD')
          this.d3 = this.api.getDateByFormat(rangeList[3], 'MM/DD')
          this.d4 = this.api.getDateByFormat(rangeList[4], 'MM/DD')
          this.d5 = this.api.getDateByFormat(rangeList[5], 'MM/DD')
          this.d6 = this.api.getDateByFormat(rangeList[6], 'MM/DD')
          
          this.statisticList.map(item => {
            // const rvt = String(item.RECENT_VISIT_TIME)
            const rd = String(item.REG_DATE)
            const id = item.ID
            type = item.TYPE

            if(id !== '1513898286') {
              if(!(item.MAIL === null || item.POINT === 0)) {
                this.realUser++   // 순회원
              }
              if(item.OS === 'Windows') this.windows++
              else if(item.OS === 'Android') this.android++
              else if(item.OS === 'iPhone') this.iPhone++
              else if(item.OS === 'Mac') this.Mac++
              
              // 방문자
              // if(rvt.indexOf(rangeList[0]) !== -1) this.rvv0++
              // else if(rvt.indexOf(rangeList[1]) !== -1) this.rvv1++
              // else if(rvt.indexOf(rangeList[2]) !== -1) this.rvv2++
              // else if(rvt.indexOf(rangeList[3]) !== -1) this.rvv3++
              // else if(rvt.indexOf(rangeList[4]) !== -1) this.rvv4++
              // else if(rvt.indexOf(rangeList[5]) !== -1) this.rvv5++
              // else if(rvt.indexOf(rangeList[6]) !== -1) this.rvv6++
  
              // 회원가입
              if(rd.indexOf(rangeList[0]) !== -1) this.uv0++
              else if(rd.indexOf(rangeList[1]) !== -1) this.uv1++
              else if(rd.indexOf(rangeList[2]) !== -1) this.uv2++
              else if(rd.indexOf(rangeList[3]) !== -1) this.uv3++
              else if(rd.indexOf(rangeList[4]) !== -1) this.uv4++
              else if(rd.indexOf(rangeList[5]) !== -1) this.uv5++
              else if(rd.indexOf(rangeList[6]) !== -1) this.uv6++
            }
          })
        }
      } else if(type === 'pointHistory') {
        let checkedId = Array()
        let visitCntMap = new Map()
        this.historyList.map(item => {
          const regDate = item.REG_DATE
          const point = Number(item.POINT)
          const id = item.ID
          
          type = item.TYPE

          if(type === '로그인' && id !== '1513898286' ) {
            let cnt
            if(id !== '1513898286') {
              if(visitCntMap.has(id)) {
                cnt = visitCntMap.get(id)
                cnt++
              } else cnt = 1
              visitCntMap.set(id, cnt)
            }
            
            // if(checkedId.indexOf(id) === -1 && Number(regDate) > Number(rangeList[0])) {
            if(checkedId.indexOf(id) === -1 && point > 0) {
              checkedId.push(regDate + id)
              // 재방문자
              if(regDate === rangeList[0]) this.vv0++
              if(regDate === rangeList[1]) this.vv1++
              if(regDate === rangeList[2]) this.vv2++
              if(regDate === rangeList[3]) this.vv3++
              if(regDate === rangeList[4]) this.vv4++
              if(regDate === rangeList[5]) this.vv5++
              if(regDate === rangeList[6]) this.vv6++
            }

          }
        })
        visitCntMap = new Map([...visitCntMap.entries()].sort((a, b) => b[1] - a[1]))
        
        this.visitCntList = Array()
        let idx = 0
        for (let [id, cnt] of visitCntMap) {
        //   await this.api.axiosGql('SELECT_MYPAGE', {type:'SELECT_MYPAGE', id:id})
        //   .then( async (res) => {
        //     let json = res.data
        //     if(json !== null) {
        //       const mail = String(json[0].MAIL)
        //       if(mail !== null) {
        //         const split = mail.split('@')
                // this.visitCntList.push({mail:split[0] + '\n@' + split[1], cnt:cnt})
                this.visitCntList.push({mail:id, cnt:cnt})
                if(++idx === 7) break
        //       }
        //     }
        //   })
        }
      }

    },
    clearOrder() {
      this.sv0 = 0 
      this.sv1 = 0
      this.sv2 = 0.0
      this.sv3 = 0 
      this.sv4 = 0
      this.sv5 = 0 
      this.sv6 = 0
      this.sv7 = 0 
      this.sv8 = 0
      this.sv9 = 0
      this.sv10 = 0
      this.sv11 = 0
      this.sv12 = 0
      this.sv13 = 0
      this.sv14 = 0
      this.sv15 = 0
      this.sv16 = 0
      this.sv17 = 0
      this.sv18 = 0
      this.sv19 = 0
      this.sv20 = 0
      this.sv21 = 0
      this.sv22 = 0
      this.sv23 = 0
      this.sv24 = 0
      this.sv25 = 0
    },
    clearUser() {
      this.allUser = 0
      this.realUser = 0
      this.d0 = 0
      this.d1 = 0
      this.d2 = 0
      this.d3 = 0
      this.d4 = 0
      this.d5 = 0
      this.d6 = 0
      this.vv0 = 0
      this.vv1 = 0
      this.vv2 = 0
      this.vv3 = 0
      this.vv4 = 0
      this.vv5 = 0
      this.vv6 = 0
      this.uv0 = 0
      this.uv1 = 0
      this.uv2 = 0
      this.uv3 = 0
      this.uv4 = 0
      this.uv5 = 0
      this.uv6 = 0
      // this.rvv0 = 0
      // this.rvv1 = 0
      // this.rvv2 = 0
      // this.rvv3 = 0
      // this.rvv4 = 0
      // this.rvv5 = 0
      // this.rvv6 = 0
      this.windows = 0
      this.android = 0
      this.iPhone = 0
      this.Mac = 0
    }
  }
}
</script>

<style scoped>
.table-value {
  width:14%;
  font-style: italic;
  color:red;
  text-align:center;
}
.table-title {
  font-weight: bolder;
}
.table-title-row {
  background-color: aliceblue;
  text-align:center;
}
#statistics > .main > table > tr > td {
  height:30px;
}
</style>