<template>
  <div >
    <possess ref="possessTag" :isEmpty="isEmpty" :num="possessNum" :possessList="possessList" @click="click" :key="possessKey" :page="this.api.getCookies('page')"/>
    <inventory />
    <!-- <br>
    <sheet /> -->
    <br><br><br>
    <div v-if="contractType === 'search'">
      <div class="div_title" >
        <span>
          <select class="select-contract" style="width:90px;text-align:center;float:none;margin-left:5px;" v-model="contractType" @change="change({type:'contractType', value:'regist'})" > 
            <option value="search">주문조회</option>
            <option value="regist">주문등록</option>
          </select>
          <label v-if="this.totalCnt !== 0">{{this.totalCnt}}건</label>
        </span>
        <span>
          <span ref="search" >
            <Button class="select-contract" v-on:click="click({type:'search'})" label="조회" raised size="small" />
          </span>
          <select class="select-contract" style="max-width:70px;" v-model="item" ref="contract_select_box"> 
            <option value = "all">전체</option>
            <option v-for="(item, index) in itemList" :value="item.NAME" v-bind:key="index">{{item.NAME}}</option>
          </select>
          <select class="select-contract" v-model="cnt"> 
            <option value="10">10개씩</option>
            <option value="30">30개씩</option>
            <option value="50">50개씩</option>
            <option value="100">100개씩</option>
          </select>
          <label ref="lbl_detail_symbol" class="contract-detail-search" @click="detailSearch()" style="margin-right: 10px;margin-left: 2px;">∨</label>
          <label class="contract-detail-search link" @click="detailSearch()">상세검색</label>
        </span>
      </div>
      <div ref="groupDetailGroup" class="card main" style="margin-top: 20px; background-color: aliceblue; display:none">
        <table>
          <tr>
            <td style="max-width: 70px; min-width: 70px;">
              <datepicker v-model="pickDateFrom" />
            </td>
            <td style="padding-left:8px;">&#126;</td>
            <td style="max-width: 70px; min-width: 70px;">
              <datepicker v-model="pickDateTo" />
            </td>
            <td style="width:100%">
              <input class="button" type="button" v-on:click="click({type:'searchDetail'})" value="조회" ref="searchDetail">
              <input ref="conditionValue" type="text" style="max-width:50px; padding: 5px; float:right; margin-right:10px;" placeholder="검색어" v-on:keyup.enter="click({type:'searchDetail'})">
              <select class="select-contract" style="margin-top: 0px;max-width: 60px;" v-model="conditionDetail" @change="change({type:'condition'})"> 
                <option value = "">선택</option>
                <option value = "name">이름</option>
                <option value = "clearance">통관부호</option>
                <option value = "invoice">송장번호</option>
                <option value = "phone">폰번호</option>
                <option value = "solair">홍콩신청서</option>
                <option v-for="(item, index) in companyList" :value="item.NUM" v-bind:key="index">{{item.NAME}}</option>
              </select>
            </td>
          </tr>
          <tr>
            <td colspan="3">
              <button class="small-btn" @click="click({type:'range', value:0})">오늘</button>
              <button class="small-btn" @click="click({type:'range', value:1})">이번달</button>
              <button class="small-btn" @click="click({type:'range', value:30})">한달</button>
            </td>
          </tr>
        </table>
      </div>
      <div style="margin-top: 10px;">
        <div v-if="orderList2.length === 0" class="card main no-result"> 
          조회 내역이 없습니다.
        </div>
        <div v-else class="card main">
          <DataTable v-model:expandedRows="expandedRows" :value="orderList2" dataKey="IDX" @rowExpand="onRowExpand" >
            <Column expander style="max-width:10px;" />
            <Column field="IDX" header="IDX"  />
            <Column field="" header="등록일" >
              <template #body="slotProps" >
                {{ this.api.getDateByFormat(slotProps.data.ORDER_DATE, 'YYYY.MM.DD') }}
              </template>
            </Column>
            <Column field="NAME" header="상품명" />
            <Column field="" header="상태" style="min-width:90px;">
              <template #body="slotProps" >
                {{this.api.getOrderStatus(Number(slotProps.data.STATUS))}}
              </template>
            </Column>
            <template #expansion="slotProps" >
              <contractc :deliveryKrCompany="slotProps.data.DELIVERY_KR_COMPANY" :status="this.api.getOrderStatus(Number(slotProps.data.STATUS))" :amount="slotProps.data.AMOUNT" :deliveryPrice2="slotProps.data.DELIVERY_PRICE2" :country="slotProps.data.COUNTRY" :invoiceNumber="slotProps.data.INVOICE_NUMBER"  :idx="slotProps.data.IDX" :name="slotProps.data.NAME" :notice="slotProps.data.NOTICE" :uid="slotProps.data.UID"  />
            </template>
          </DataTable>
          <div style="text-align: center; margin: 20px;">
            <label @click="paging('prev')" class="link">
              이전
            </label>
            <span style="margin:20px">{{page}}</span>
            <label @click="paging('next')" class="link">
              다음
            </label>
            <label @click="excel()" class="link" style="float: right; color: green;">Excel</label>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <contract_order @selectChange="change" :itemList="itemList"/>
    </div>
    <br>
    <div class="div_title">
      <table class="table" style="width:100%; ">
        <tr style="font-weight: bold;">
          <td><span style="color: #ff7f50;">&#9654;</span> 견적서</td>
          <td>
            <label class="button" type="button" @click="click({type:'save'})" style="max-width:200px;float:right;" >저장</label>
            <label class="button" type="button" for="inputFile" style="max-width:200px;float:right;margin-right:10px;">등록</label>
            <input type="file" id="inputFile" ref="inputFile" @change="readFile" style="max-width:0px;margin-bottom:5px;" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet">
            <input ref='hkdCurrency' type="number" placeholder="홍콩환율" style="max-width:45px;float:right;margin-top:3px;margin-right:10px;" />
            <label ref="totDeliPrice" style="float:right;margin-right:10px;margin-top:4px;color:red;"/>
          </td>
        </tr>
      </table>
    </div>
    <div v-if="orderList.length === 0" class="card main no-result"> 
      조회 내역이 없습니다.
    </div>
    <table v-else id="table-order" style="width:100%;text-align:center;margin-top:10px;">
      <tr>
        <th>No</th>
        <th>날짜</th>
        <th>송장번호</th>
        <th>수취인</th>
        <th>상품명</th>
        <th>무게</th>
        <th>배송비</th>
        <th>원가</th>
        <th style="max-width: 70px">소매가</th>
        <th>수량</th>
      </tr>
      <tr class="orderTr" v-for="(order, index) in orderList" v-bind:key="index">
        <td>{{ order.idx }}</td>
        <td>{{ order.date }}</td>
        <td>{{ order.invoice }}</td>
        <td>{{ order.orderName }}</td>
        <td><input style="max-width: fit-content" type="text" :value="order.itemName" /></td>
        <td>{{ order.weight }}</td>
        <td>{{ order.price }}</td>
        <td>{{ Math.ceil(order.price * this.hkdCurrency) }}</td>
        <td v-if="innomaxItemNameList.indexOf(order.itemName) !== -1"><input style="max-width: 70px;text-align:center;" :value="Math.ceil(order.price * (innomaxHkdCurrency+10)/ 100)*100 + 3200"></td>
        <td v-else-if="estherItemNameList.indexOf(order.itemName) !== -1"><input class="sfPrice" style="max-width: 70px;text-align:center;" :value="Math.ceil(order.price * (this.hkdCurrency+10)/ 100)*100"></td>
        <td v-else ><input style="max-width: 70px;text-align:center;" :value="Math.ceil(order.price * (this.hkdCurrency+10)/ 100)*100 + 3200"></td>
        <td v-if="order.invoice !== undefined && (String(order.invoice).substring(0,2) === 'SF' || String(order.invoice).substring(0,2) === 'sf')"><input class="sfAmount" ref="item_amount" style="max-width: 10px" type="number" value=1 @focusout="focusout({idx:index})" /></td>
        <td v-else><input ref="item_amount" style="max-width: 10px" type="number" value=1 /></td>
      </tr>
      <!-- <br>
      <tr>
        <td colspan="10">
          <input v-if="orderList.length !== 0" @click="click({type:'cnt'})" class="button" type="button" style="min-width:100px;" value="수량수정"/>
        </td>
      </tr> -->
    </table>
  </div>
  <Modal v-model='isShow' :close='closeModal' style="z-index:99999999999;">
    <div class="modal">
      <span style="color: #ff7f50;float:left;">&#9654;</span> <label style="font-weight: bolder;float:left;"> 재고이력</label>
      <br><br>
      <div class="card main" style="text-align: left;">
        <p v-if="contentList.length === 0" class="point"><span >이력이 없습니다.</span></p>
        <p v-else class="point" v-for="(content, index) in contentList" :key="index">
          <span>[ {{this.api.getDateByFormat(content.REG_DATE, 'YYYY.MM.DD')}} ] &nbsp; {{content.CONTENT}} </span>
        </p>
      </div>
      <div style="text-align: center;margin-bottom:20px;">
        <label @click="contentPaging('prev')" class="link">
          이전
        </label>
        <span style="margin:20px">{{ contentPage}}</span>
        <label @click="contentPaging('next')" class="link">
          다음
        </label>
      </div>
      <span><Button @click="closeModal()" label="닫기" raised /></span>
    </div>
  </Modal>  
</template>
<script>
import datepicker from 'vue3-datepicker'
import contractc from '@/components/content/contract_content.vue'
import contract_order from '@/components/contract/contract_order.vue'
// import sheet from '@/components/admin/sheet.vue'
import inventory from '@/components/admin/inventory_change.vue'

const xlsx = require('xlsx')
import { ref } from 'vue'
import possess from '@/components/common/possess.vue'

export default {
  components: {
    possess, datepicker, 
    contractc, 
    contract_order, inventory
  },  
  mounted() {
    let firstDay = new Date(this.pickDateFrom.getFullYear(), this.pickDateFrom.getMonth(), 1);
    this.pickDateFrom = firstDay
    this.setItems({comnum:undefined, useYn:true})
    this.api.axiosGql('SELECT_COMPANY', {type:'SELECT_COMPANY', num:undefined})
    .then( async (res) => {
      const json = res.data 
      if( !(json === null || json === undefined || json.length === 0) ) {
        this.companyList = json
      }
    })
  }, 
  setup() {
    const isShow = ref(false)
    async function showModal () {
      isShow.value = true;
    }
    function closeModal () {
      isShow.value = false
    }
    function onRowExpand (event) {
      expandedRows.value = {[event.data.IDX]:true}
    }
    const expandedRows = ref({})
    return {
      closeModal, showModal, isShow, expandedRows, onRowExpand
    }
  },
  data() { 
    return { 
      isEmpty:false,
      contractType:'search',
      cnt:"10",
      possessNum:'',
      totalCnt:0,
      item: 'all',
      pickDateFrom:ref(new Date()),
      pickDateTo:ref(new Date()),
      itemList: Array(),
      companyList: Array(),
      contentPage:1,
      page:1,
      orderedMap: new Map(),
      conditionDetail:'',
      param:{},
      orderList2: Array(),
      contentList: ref([]),
      possessList: ref([]),
      hkdCurrency:0,
      innomaxHkdCurrency:0.0,
      totalPrice:0,
      possessKey:0,
      totDeliPrice:0,
      innomaxItemNameList: Array(),
      estherItemNameList: Array(),
      contractcKey:-1,
      orderList: Array()
    }
  },
  methods: {
    focusout(param) {
      const idx = param.idx
      let sfPriceInput = document.getElementsByClassName('sfPrice')[idx]
      let sfAmountInput = document.getElementsByClassName('sfAmount')[idx]
      const presAmount = sfAmountInput.value
      const restAmount = presAmount - 1
      
      if(restAmount > 0) {
        sfPriceInput.value = Number(sfPriceInput.value) + 500 * restAmount 
      }
    },
    async contentPaging(type) {
      if(type === 'prev') {
        if(this.contentPage === 1) {
          this.$notify('첫 페이지입니다.')
          return
        } else this.contentPage -= 1
      } else this.contentPage += 1
      await this.api.axiosGql('SELECT_ORDER_HISTORY', {type:'SELECT_ORDER_HISTORY', id:undefined, page:this.contentPage})
      .then( async (res) => {
        let json = res.data
        if(json.length === 0) {
          this.contentPage -= 1
          alert('마지막 페이지입니다.')
          this.$notify('마지막 페이지입니다.')
        } else {
          this.contentList = res.data
          this.showModal()
        }
      }).catch( (err) => {
        console.log(err)
        if(type === 'prev') this.contentPage += 1
        else this.contentPage -= 1
      })
    },
    async excel() {
      let execelList = Array()
      for(let i=0; i<this.orderList2.length; i++) {
        const item = this.orderList2[i]
        await this.api.axiosGql('SELECT_ORDER_INFO_BY_UID', {type:'SELECT_ORDER_INFO_BY_UID', ID: "", UID:item.UID})
        .then( async (res) => {
          let result = res.data
          execelList.push({
            IDX:item.IDX, 
            주문일:item.ORDER_DATE,
            상품명:item.NAME,
            진행상황:this.api.getOrderStatus(Number(item.STATUS)),
            택배:item.DELIVERY_KR_COMPANY,
            송장번호:item.INVOICE_NUMBER,
            배송비:item.DELIVERY_PRICE2,
            수량:item.AMOUNT,
            국가:item.COUNTRY,
            수취인:result.ORDER_NAME,
            폰번호:result.PHONE,
            통관부호:result.CLEARANCE,
            도로명주소:result.ADDR_ROAD,
            상세주소:result.ADDR_DETAIL
          })
        }).catch( (err) => {
          console.log(err)
          this.$notify('다시 시도해주세요')
          alert('다시 시도해주세요')
        })
      }
      const workBook = xlsx.utils.book_new()
      const workSheet = xlsx.utils.json_to_sheet(execelList)
      xlsx.utils.book_append_sheet(workBook, workSheet, 'geek9.kr')
      xlsx.writeFile(workBook,  this.api.getDateByFormat(new Date(), 'YYYYMMDD') + '.xlsx')
    },    
    detailSearch() {
      if(this.$refs.groupDetailGroup.style.display === 'none' ) {
        this.$refs.lbl_detail_symbol.innerHTML = '∧'
        this.$refs.groupDetailGroup.style.display = ''
        this.$refs.search.style.display = 'none'
      } else {
        this.$refs.contract_select_box.disabled = false
        this.conditionDetail = ''
        this.$refs.conditionValue.value = ''
        this.$refs.lbl_detail_symbol.innerHTML = '∨'
        this.$refs.groupDetailGroup.style.display = 'none'
        this.$refs.search.style.display = ''
      } 
    },
    change(param) {
      this.orderList2 = Array()
      const type = param.type
      if(type === 'condition') {
        const conditionDetail = this.conditionDetail
        if(conditionDetail === '') this.$refs.conditionValue.value = ''
        else if(conditionDetail ==='solair') {
          this.$refs.conditionValue.value = ''
          this.$refs.conditionValue.disabled = true
          this.$refs.contract_select_box.disabled = true
        } else if(!(conditionDetail ==='invoice' || conditionDetail ==='phone' || conditionDetail ==='clearance' || conditionDetail ==='name' || conditionDetail ==='')) {
          this.$refs.conditionValue.value = ''
          this.$refs.conditionValue.disabled = true
          this.item= 'all'
          this.$refs.contract_select_box.disabled = true
        } else {
          this.$refs.conditionValue.disabled = false
          this.$refs.contract_select_box.disabled = false
        }
      } else if(type === 'contractType') {
        this.orderedMap.clear()
        this.contractType = param.value
        this.totalCnt = 0
      }
    },    
    paging(type) {
      // this.collapseAll()
      if(type === 'prev') {
        if(this.page === 1) {
          this.$notify('첫 페이지입니다.')
          return
        } else this.page -= 1
      } else this.page += 1
      this.param.page =  this.page
      this.companyItemSearch()
    },
    // collapse(param) {
    //   const sub_idx = param.idx
    //   const sub_menu_list = document.getElementById("contract-solair")
    //   let col_btn_list = sub_menu_list.getElementsByTagName("button")
    //   let col_con_list = sub_menu_list.getElementsByClassName("content")
    //   const btn = col_btn_list[sub_idx]
    //   const con = col_con_list[sub_idx]
    //   if(con === undefined) {
    //     this.collapseAll()
    //     this.paging('next')
    //     const el = document.getElementById('contract-solair')
    //     el.scrollIntoView()
    //   } else {
    //     // open
    //     const btnClass = btn.getAttribute("class") 
    //     if(btnClass === 'collapsible select' || btnClass === 'collapsible select text-cut') {
    //       btn.setAttribute("class", "collapsible text-cut")
    //       btn.style.color = "black"
    //       btn.style.fontWeight = ""
    //       con.style.maxHeight = null
    //     } else {    // close
    //       this.collapseAll()
    //       btn.setAttribute("class", "collapsible select text-cut")
    //       btn.style.fontWeight = "bold"
    //       con.style.maxHeight = con.scrollHeight + "px"
    //       this.emitter.emit('orderRefresh' + param.orderIdx , {idx:param.orderIdx, uid:param.uid})
    //     }
    //   }
    // },    
    // collapseAll() {
    //   const sub_menu_list = document.getElementById("contract-solair")
    //   if(sub_menu_list !== null && sub_menu_list !== undefined) {
    //     let col_btn_list = sub_menu_list.getElementsByTagName("button")
    //     let col_con_list = sub_menu_list.getElementsByClassName("content")
    //     for(var i = 0; i < col_btn_list.length; i++) {
    //       let colBtn = col_btn_list[i]
    //       if(colBtn !== undefined) {
    //         colBtn.setAttribute("class", "collapsible text-cut")
    //         colBtn.style.color = "black"
    //         colBtn.style.fontWeight = ""
    //       } 
    //       let tmpCon = col_con_list[i]
    //       if(tmpCon !== undefined ) tmpCon.style.maxHeight = null
    //     }
    //   }
    // },
    async companyItemSearch() {
      const selectedItem = this.$refs.contract_select_box.value
      let list = Array()
      if(selectedItem === 'all') {
        this.itemList.forEach((item) => {
          list.push(item.NAME)
        })
      } else list.push(selectedItem)
      
      if(list.length === 0) {
        this.$notify('등록된 상품이 없습니다.\nGEEK9측에 문의해주세요!')
        alert('등록된 상품이 없습니다.\nGEEK9측에 문의해주세요!')
      } else {
        this.param.list = list
        await this.api.axiosGql('SELECT_COMPANY_ORDER', this.param)    
        .then( async (res) => {
          const origin = res.data
          
          if(origin === null || origin === 'null' || origin === undefined || origin.length === 0) {
            this.$notify('데이터가 없습니다.')
            if(this.page === 1) {
              this.orderList2 = Array()
              this.totalCnt = 0
              this.orderedMap.clear()
            }
            this.page -= 1
          } else {
            this.orderList2 = origin
            this.totalCnt = origin.length 
            await this.sum()
          }
          this.contractcKey++
        })
      }
    },
    async sum() {
      this.orderedMap.clear()
      this.orderList2.forEach((item) => {
        const itemName = item.NAME
        const amount = Number(item.AMOUNT)
        const delivery = Number(item.DELIVERY_PRICE2)
        const status = Number(item.STATUS)

        if(this.orderedMap.has(itemName)) {
          let json = this.orderedMap.get(itemName)
          json.amount += amount
          json.delivery += delivery
        } else {
          if(status !== -2 && status !== -1) {
            let json = {
              amount:amount,
              delivery:delivery
            }
            this.orderedMap.set(itemName, json)
          }
        }
      })
    },    
    async setItems(param) {
      this.api.axiosGql('SELECT_ITEM', {type:'SELECT_ITEM', num:param.comnum, useYn:param.useYn})
      .then( (res) => {
        const json = res.data
        if(json.length === 0) {
          this.page -= 1
        } else this.itemList = res.data
      })
    },
    async click(param) {
      const type = param.type
      const id = undefined
      const mail = undefined

      if(type === 'searchDetail') {
        // this.collapseAll()
        this.page             = 1
        let dateF             = new Date(this.pickDateFrom)
        let dateT             = new Date(this.pickDateTo)
        let pickDateFrom      = new Date(dateF.getTime() - (dateF.getTimezoneOffset() * 60000)).toISOString()
        let pickDateTo        = new Date(dateT.getTime() - (dateT.getTimezoneOffset() * 60000)).toISOString()
        const condition       = this.conditionDetail
        const conditionValue  = String(this.$refs.conditionValue.value).replace(/ /gi, '') 
        const startDate       = Number(new Date(pickDateFrom).toISOString().slice(0,10).replace(/-/g,""))
        const endDate         = Number(new Date(pickDateTo).toISOString().slice(0,10).replace(/-/g,""))
        
        if(startDate > endDate) this.$notify('날짜를 확인해 주세요.')
        if(condition === 'solair') {
          this.param = {type:'SELECT_SOLAIR_ORDER', startDate:startDate, endDate:endDate, page:this.page, cnt:Number(this.cnt)}
          
          await this.api.axiosGql('SELECT_SOLAIR_ORDER', this.param)    
          .then( async (res) => {
            const origin = res.data
            if(origin === null || origin === 'null' || origin === undefined || origin.length === 0) {
              alert('데이터가 없습니다.')
              if(this.page === 1) {
                this.orderList2 = Array()
                this.totalCnt = 0
                this.orderedMap.clear()
              }
              this.page -= 1
            } else {
              this.orderList2 = origin
              this.totalCnt = origin.length 
              await this.sum()
            }
          })
        } else if( !(condition === 'name' || condition === 'clearance' || condition === 'invoice' || condition === 'phone' || condition === '' ) ) {
          this.param = {type:'SELECT_COMPANY_ORDER', startDate:startDate, endDate:endDate, condition:condition, conditionValue:conditionValue, page:this.page, cnt:Number(this.cnt)}
          this.companyItemSearch()
        } else if(condition === '' && conditionValue !== '') this.$notify('조건을 선택해 주세요.')
        else if(condition !== '' && conditionValue === '') this.$notify('검색어를 입력해 주세요.')
        else {
          this.param = {type:'SELECT_COMPANY_ORDER', startDate:startDate, endDate:endDate, condition:condition, conditionValue:conditionValue, page:this.page, cnt:Number(this.cnt)}
          this.companyItemSearch()
        }
      } else if(type === 'search') {
        // this.collapseAll()
        this.page = 1
        this.param = {type:'SELECT_COMPANY_ORDER', page:this.page, cnt:Number(this.cnt)}
        this.companyItemSearch()
      } else if(type === 'reg') {
        const comNum = this.$refs.comNumReg.value
        const phone = this.$refs.phoneReg.value
        if(comNum === '' || phone === '') {
          alert('누락된 값이 있습니다.')
          this.$notify('누락된 값이 있습니다.')
        } else {
          if(confirm('가입 이후에는 기업 변경이 불가합니다!\n기업 당당자로 등록하시겠습니까?') === true) {
            await this.api.axiosGql('SELECT_COMPANY', {type:'SELECT_COMPANY', num:comNum})
            .then( async (res) => {
              const json = res.data 
              if(json === null || json === undefined || json.length === 0) {
                alert('입력하신 사업자번호로 등록된 기업이 없습니다.\n문의 게시판에 사업자등록 요청을 해주세요')
                this.$notify('입력하신 사업자번호로 등록된 기업이 없습니다.\n문의 게시판에 사업자등록 요청을 해주세요')
              } else {
                await this.api.axiosGql('SELECT_USER', {type:'SELECT_USER', id:id, mail: mail, service: comNum})
                .then( async (res) => {
                  const json = res.data
                  if(json === null || json === undefined || json.length === 0) {
                    await this.api.axiosGql('UPSERT_USER', {type:'UPSERT_USER', name: this.api.getCookies('nickname'), id: id, mail: mail, service: comNum, phone: phone, userType:1})
                    .then((res1) => {
                      if(res1.data === null) {
                        alert('이미 기업 담당자님으로 가입되어 있는 폰번호입니다.')
                        this.$notify('이미 기업 담당자님으로 가입되어 있는 폰번호입니다.')
                      } else {
                        this.menu_click(0)
                        this.$notify('가입되었습니다.')
                        alert('입력하신 폰번호로 기업 담당자님으로 등록되었습니다.')
                        this.api.setCookies('comnum', comNum)
                      }
                    })
                  } else {
                    alert('이미 기업 담당자로 등록된 계정입니다.')
                    this.$notify('이미 기업 담당자로 등록된 계정입니다.')
                  }
                })
              }
            })
          }
        }
      } else if(type === 'log') {
        const comNum = this.$refs.comNumLog.value
        if(comNum === '') {
          alert('누락된 값이 있습니다.')
          this.$notify('누락된 값이 있습니다.')
        } else {
          await this.api.axiosGql('SELECT_USER', {type: 'SELECT_USER', id: id, mail: mail, service: comNum})
          .then( async (res) => {
            const json = res.data
            if(json === null || json === undefined || json.length === 0) {
              alert('등록된 기업 담당자님이 아닙니다.')
              this.$notify('등록된 기업 담당자님이 아닙니다.')
            } else {
              await this.api.axiosGql('SELECT_COMPANY', {type:'SELECT_COMPANY', num:comNum})
              .then( async (res1) => {
                const json = res1.data 
                if(json === null || json === undefined || json.length === 0) {
                  alert('입력하신 사업자번호로 등록된 기업이 없습니다.\n문의 게시판에 사업자등록 요청을 해주세요')
                  this.$notify('입력하신 사업자번호로 등록된 기업이 없습니다.\n문의 게시판에 사업자등록 요청을 해주세요')
                } else {
                  this.$notify('기업고객 로그인 성공')
                  this.api.setCookies('comnum', comNum)
                  this.comName = json[0].NAME
                  this.comNum = json[0].NUM
                  this.contractKey++
                  this.emitter.emit("setValue", {comName:this.comName})
                  this.setItems({comnum:comNum, useYn:true})
                }
              })
            }
          })
        }
      } else if(type === 'range') {
        const val = param.value
        this.pickDateTo = ref(new Date())
        if(val === 0) {
          this.pickDateFrom = ref(new Date())
        } else if(val === 1) {
          let firstDay = new Date(this.pickDateTo.getFullYear(), this.pickDateTo.getMonth(), val);
          this.pickDateFrom = firstDay
        } else if(val === 30) {
          let before30days = new Date(this.pickDateTo.getFullYear(), this.pickDateTo.getMonth(), this.pickDateTo.getDate() - val);
          this.pickDateFrom = before30days
        }
      } else if(type === 'save') {
        if(this.orderList.length === 0) {
          this.$notify('주문 내역이 없습니다')
        } else {
          let amountMap = new Map()
          const orderTr = document.querySelectorAll('.orderTr')
          
          for(let i = 0; i < orderTr.length; i++) {
            const tr        = orderTr[i]
            const tdList    = tr.childNodes
            const invoice   = tdList[2].innerHTML
            const orderName = tdList[3].innerHTML
            const itemName  = tdList[4].childNodes[0].value
            const delivery  = tdList[7].innerHTML
            const retail    = tdList[8].childNodes[0].value
            const amount    = tdList[9].childNodes[0].value
            
            if(amountMap.has(itemName)) amountMap.set(itemName, amountMap.get(itemName) + Number(amount))
            else amountMap.set(itemName, Number(amount))

            await this.api.axiosGql('SELECT_UID_BY_NAME', {type:'SELECT_UID_BY_NAME', name:orderName})
            .then( async (res) => {
              const uidList = res.data
              for(let j = 0; j < uidList.length; j++) {
                const uid = uidList[j].UID
                const param = {
                  type:'UPDATE_ORDER_ESTIMATE',
                  uid:uid,
                  invoiceNumber:invoice,
                  name:itemName,
                  deliveryPrice1:delivery,
                  deliveryPrice2:retail,
                  orderDate:this.api.getRange(-1).startDate
                }
                this.api.axiosGql('UPDATE_ORDER_ESTIMATE', param)
                .then( async (res) => {
                  console.log(res)
                })
                .catch( async (err) => {
                  console.log(err)
                })
              }
              this.$notify('반영완료')
            })
          }
          let historyMap = new Map()
          let itemList = Array()
          let itemMap = new Map()
          let json

          for(let [itemName, amount] of amountMap) {
            const sellAmount = Number(amount)
            await this.api.axiosGql('UPDATE_ITEM_STOCK', {type:'UPDATE_ITEM_STOCK', name:itemName, amount:sellAmount})
            .then( async () => {

              if(itemMap.has(itemName)) itemMap.set(itemName, itemMap.get(itemName) + sellAmount)
              else itemMap.set(itemName, sellAmount)

              await this.api.axiosGql('SELECT_ITEM', {type:'SELECT_ITEM', useYn:param.useYn})
              .then( async (res) => {
                if(json === undefined) {
                  json = res.data
                  for(let i = 0; i < json.length; i++) {
                    const comNum = json[i].NUM
                    const itemName = json[i].NAME
                    if(historyMap.has(comNum)) {
                      itemList = historyMap.get(comNum)
                      itemList.push(itemName)
                    } else {
                      itemList = Array()
                      itemList.push(itemName)
                      historyMap.set(comNum, itemList)
                    }
                  }
                }
              })
            })
            .catch( async (err) => {
              console.log(err)
            })
          }
          let contentList = new Map()
          for(let [comNum, itemList] of historyMap) {
            for(let [itemName, sellAmount] of itemMap) {
              if(itemList.includes(itemName)) {
                if(contentList.has(comNum)) contentList.set(comNum, contentList.get(comNum) + ', ' +  itemName + ' - ' + sellAmount + '개 출고')
                else contentList.set(comNum, itemName + ' - ' + sellAmount + '개 출고')
              }
            }
          }
          for(let [comNum, content] of contentList) {
            await this.api.axiosGql('INSERT_ORDER_HISTORY', {type:'INSERT_ORDER_HISTORY', id:comNum, uidBefore:'', uidAfter:'', content:content})
            .then( async () => {
            }).catch( async (err) => {
              console.log(err)
            }) 
          }
        }
      } else if(type === 'possess') {
        this.isEmpty = param.isEmpty
        this.api.axiosGql('SELECT_ITEM', {type:'SELECT_ITEM', num:param.num, useYn:true, isEmpty:this.isEmpty})
        .then( (res) => {
          const json = res.data
          this.possessList = json
          console.log(param.isEmpty)
          if(param.num === undefined) this.possessNum = ''
          else this.possessNum = param.num
          
          this.possessKey++
        })
      } else if(type === 'history') {
        await this.api.axiosGql('SELECT_ORDER_HISTORY', {type:'SELECT_ORDER_HISTORY', id:undefined, page:1})
        .then( async (res) => {
          this.contentList = res.data
          this.showModal()
        })
      } else if(type === 'modify') {
        if(this.possessList.length === 0) {
          alert('재고 내역이 없습니다')
        } else {
          if(confirm('재고를 수정하시겠습니까?')) {
            const item = this.$refs.possessTag.$refs.table_val
            const inputList = item.querySelectorAll('td>input')
              
            for(let i = 0; i < this.possessList.length; i++) {
              const originItem = this.possessList[i]
              const originItemName = originItem.NAME
              const originAmount = Number(originItem.STOCK)
              const newAmount = Number(inputList[i].value)
              if(newAmount !== originAmount) {
                await this.api.axiosGql('UPDATE_ITEM_STOCK', {type:'UPDATE_ITEM_STOCK', name:originItemName, amount:(originAmount-newAmount)})
                // await this.api.axiosGql('UPDATE_ITEM_STOCK', {type:'UPDATE_ITEM_STOCK', name:originItemName, amount:newAmount})
                .then( async () => {
                  this.$notify('수정완료 : ' + originItemName)
                })
                .catch( async (err) => {
                  console.log(err)
                })
              }
            }
          }
        }
      } 
      // else if(type === 'cnt') {
      //   const orderTr = document.querySelectorAll('.orderTr')
      //   for(let i = 0; i < orderTr.length; i++) {
      //     let trList = orderTr[i]
      //     let tdList = trList.querySelectorAll('td')

      //     const orderName = tdList[3].innerText
      //     const itemName = tdList[4].querySelector('input')

      //     await this.api.axiosGql('GET_RECENT_ORDER_AMOUNT', {type:'GET_RECENT_ORDER_AMOUNT', orderName:orderName, name:itemName})
      //     .then( async (res) => {
      //       console.log(res)
      //     })
      //     .catch( async (err) => {
      //       console.log(err)
      //     })
      //   }
      // }
    },
    readFile(event) {
      this.hkdCurrency = Number(this.$refs.hkdCurrency.value)
      if(this.hkdCurrency === 0) {
        alert('홍콩환율을 입력해주세요')
        this.$notify('홍콩환율을 입력해주세요')
      } else {
        let orders = {}
        const reader = new FileReader()
        const file = event.target.files[0]
  
        reader.onload = async (e) => {
          event.target.value=''
          let data = e.target.result
          data = new Uint8Array(data)
          let excelFile = xlsx.read(data, { type: "array" })
          
          excelFile.SheetNames.forEach(function(sheetName) {
            const roa = xlsx.utils.sheet_to_json(excelFile.Sheets[sheetName], { header: 1 } )
            if (roa.length) orders[sheetName] = roa
          })
          let keys = Object.keys(orders)
          if(keys.length === 0) {
            alert('엑셀 파일에 주문 내역이 없습니다')
            this.$notify('엑셀 파일에 주문 내역이 없습니다')
            event.target.value=''
          } else {
            const excelContent = orders[keys[keys.length-1]]
            this.totDeliPrice = 0
            this.orderList = Array()
            
            if(this.innomaxItemNameList.length === 0) {
              await this.api.axiosGql('SELECT_ITEM', {type:'SELECT_ITEM', num:'7278801252', useYn:true})
              .then( async (res) => {
                const json = res.data
                if(json.length !== 0) {
                  for(let i = 0; i < json.length; i++) {
                    this.innomaxItemNameList.push(json[i].NAME)
                  }
                }
              })
            }

            if(this.estherItemNameList.length === 0) {
              await this.api.axiosGql('SELECT_ITEM', {type:'SELECT_ITEM', num:'2118818938', useYn:true})
              .then( async (res) => {
                const json = res.data
                if(json.length !== 0) {
                  for(let i = 0; i < json.length; i++) {
                    this.estherItemNameList.push(json[i].NAME)
                  }
                }
              })
            }

            await this.api.axiosGql('SELECT_NOTICE2', {type:'SELECT_NOTICE2', priority:Number(this.api.getDateByFormat(new Date(), 'YYYYMM')), noticeType: 'innomax', useYn:true})
            .then( async (result) => {
              if(result.data !== null && result.data !== undefined) this.innomaxHkdCurrency = Number(result.data[0].CONTENT)
            }).catch( (err) => {
              console.log(err)
            })
            
            for(let i = 12; i < excelContent.length; i++) {
              const row = excelContent[i]
              if(row[0] === 'TOTAL') {
                this.totalPrice = row[11]
                break
              } else {
                const idx = row[0]
                const invoice = row[1]
                const date = String(row[2])
                const orderName = row[3]
                const itemName = row[4]  
                const weight = row[5]
                const opt1Price = row[6]
                const opt2Price = row[7]
                const opt3Price = row[8]
                const opt4Price = row[9]
                const opt5Price = row[10]
                const price = row[11]
                this.totDeliPrice += price
                if( opt1Price + opt2Price + opt3Price + opt4Price + opt5Price !== price ) {
                  alert('idx ' + idx + ' 가격이 올바르지 않습니다')
                  this.$notify('idx ' + idx + ' 가격이 올바르지 않습니다')
                  break
                } else {
                  this.orderList.push({idx:idx, invoice:invoice, date:date, orderName:orderName, itemName:itemName, weight:weight, price:price})
                }
              }
            }
            this.$refs.totDeliPrice.innerHTML = Math.ceil(this.totDeliPrice * this.hkdCurrency).toLocaleString() + ' 원(' + this.totDeliPrice.toLocaleString() + ' HKD' + ')'
          }
        }
        reader.readAsArrayBuffer(file)
      }
    }
  }
}
</script>

<style scoped>
/* .button {
  float:right;
  background-color: lightsalmon;  
  color: white;
  font-weight: bold;
  border-radius: 9px;
  border: 0px;
  padding:5px;
  max-width: 50px;
  cursor: pointer;
} */
#table-order tr:nth-child(even){
  background-color: aliceblue;
}
.collapsible {
  background-color: lightgoldenrodyellow;
  color: black;
  cursor: pointer;
  margin-right: 10px;
  margin-bottom: 5px;
  padding: 5px;
  border-radius: 9px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
}
.tabcontent {
  display: none;
  padding: 6px 12px;
  border: 1px solid #ff7f50;
  border-top: none;
}
.content {
  max-height: 0;
  height: auto;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  background-color: rgb(250, 250, 250);
}
.contract-detail-search {
  float: right;
  font-size: 10px;
  color:blue;
  vertical-align: middle;
  margin-top: 3px;
}
.small-btn {
  width:30%;
  font-size:10px;
  margin-left: 2px;
  margin-right: 2px;
}
.v3dp__input_wrapper > input {
  padding-top:5px;
  padding-bottom:5px; 
  max-width:70px;
  text-align: center;
}
.button2 {
  background-color: lightsalmon;  
  color: white;
  padding: 5px;
  font-weight: bold;
  border-radius: 9px;
  border: 0px;
  min-width: 80px;
  width:100%;
  margin:5px;
  cursor: pointer;
} 
</style>