<template>
  <table class="table" style="width:100%; ">
    <tr style="font-weight: bold;">
      <td>
        <span style="color: #ff7f50;">&#9654;</span> 
        <select style="text-align:center;margin-left:5px; padding:5px;" v-model="contractType" @change="changed()"> 
          <option value="company">업체등록</option>
          <option value="item">아이템등록</option>
        </select>
      </td>
      <td>
        <input style="max-width:50px;float:right;" type="button" v-on:click="click({type:'INSERT'})" value="등록" />
      </td>
    </tr>
  </table>
  <table v-if="contractType === 'company'" class="table" style="width:100%; ">
    <tr style="height:40px;text-align: center;">
      <td style="width:25%">업체명</td>
      <td style="width:25%"><input ref="name" type="text" placeholder="업체명"/></td>
      <td style="width:25%;text-align: center;">사업자번호</td>
      <td style="width:25%"><input ref="num" type="number" placeholder="사업자번호"/></td>
    </tr>
    <tr style="height:40px;text-align: center;">
      <td>대표자명</td>
      <td><input ref="ceo" type="text" placeholder="대표자명"/></td>
      <td>연락처</td>
      <td ><input ref="phone" type="number" placeholder="연락처"/></td>
    </tr>
    <tr style="height:40px;text-align: center;">
      <td>우편번호</td>
      <td ><input ref="addrZip" type="number" placeholder="우편번호"/></td>
      <td >도로명주소</td>
      <td ><input ref="addrRoad" type="text" placeholder="도로명주소"/></td>
    </tr>
    <tr style="height:40px;text-align: center;">
      <td>상세주소</td>
      <td><input ref="addrDetail" type="text" placeholder="상세주소"/></td>
      <td >기타</td>
      <td><input ref="etc" type="text" placeholder="기타"/></td>
    </tr>  
  </table>
  <table v-else class="table" style="width:100%;">
    <tr style="height:40px;text-align: center;">
      <td style="width:25%">사업자명</td>
      <td style="width:25%">
        <select class="select-contract"  v-model="company" ref="company_select_box"> 
          <option value="">선택</option>
          <option v-for="(company, index) in companyList" :value="company.NUM" v-bind:key="index">{{company.NAME}}</option>
        </select>
      </td>
      <td style="width:25%;text-align: center;">아이템명</td>
      <td style="width:25%"><input ref="itemName" type="text" placeholder="아이템명"/></td>
    </tr>
    <tr style="height:40px;text-align: center;">
      <td>상품링크</td>
      <td><input ref="url" type="url" placeholder="상품링크"/></td>
      <td>사진링크</td>
      <td ><input ref="imgUrl" type="url" placeholder="사진링크" /></td>
    </tr>
    <tr style="height:40px;text-align: center;">
      <td>판매가격</td>
      <td ><input ref="sellPrice" type="number" placeholder="판매가격"/></td>
      <td >해외가격</td>
      <td ><input ref="originPrice" type="text" placeholder="해외가격"/></td>
    </tr>
    <tr style="height:40px;text-align: center;">
      <td>브랜드</td>
      <td><input ref="brand" type="text" placeholder="상세주소"/></td>
      <td >국가</td>
      <td>
        <select class="select-contract" ref="country">
          <option value = "" selected>국가선택</option>
          <option value = "홍콩">홍콩</option>
          <option value = "프랑스">프랑스</option>
          <option value = "영국">영국</option>
          <option value = "스페인">스페인</option>
          <option value = "독일">독일</option>
          <option value = "이탈리아">이탈리아</option>
          <option value = "일본">일본</option>
          <option value = "중국">중국</option>
          <option value = "미국">미국</option>
          <option value = "싱가포르">싱가포르</option>
          <option value = "네덜란드">네덜란드</option>
          <option value = "호주">호주</option>
          <option value = "뉴질랜드">뉴질랜드</option>
          <option value = "대만" >대만 (+886)</option>
          <!-- <option value = "태국" >태국 (+66)</option> -->
          <!-- <option value = "덴마크">덴마크</option> -->
          <!-- <option value = "베트남">베트남</option> -->
          </select>
      </td>
    </tr>  
  </table>
</template>
<script>

export default {
  emits: ["setCookies", "setMenu", "login", "logout"],
  components: {
  },  
  mounted() {
  },
  data() { 
    return { 
      contractType:'company',
      company:'',
      companyList: Array(),
    }
  },
  methods: {
    // async request() {
    //   window.Kakao.API.request({
    //     url: '/v2/user/me',
    //     success: (response) => {
    //       this.$parent.$emit("setCookies", {login:true, id:response.id, nickname:response.properties.nickname, img:response.properties.thumbnail_image, mail:response.kakao_account.email})
    //     },
    //     fail: (err) => {
    //       this.$notify("다시 시도해주세요.")
    //       console.log(err);
    //     }
    //   });
    // },
    // async login() {
    //   window.Kakao.Auth.login({
    //     success: () => {
    //       this.tmp = '로그인'
    //       this.request();
    //     },
    //     fail: () => {
    //       this.$notify("다시 시도해주세요.")
    //     },
    //   })
    // },
    // logout() {
    //   window.Kakao.Auth.logout(() => {
    //     this.api.setCookies({login:false, id:'', nickname:'',img:'', mail:'', comnum:'', comname:''})
    //     this.tmp = 'not'
    //     this.$notify('로그아웃 되었습니다.');
    //   });
    // },   
    async click(param) {
      let type = param.type
      type += '_' + String(this.contractType).toUpperCase()
      if(type === 'INSERT_COMPANY') {
        const name = this.$refs.name.value
        const num = this.$refs.num.value
        const ceo = this.$refs.ceo.value
        const phone = this.$refs.phone.value
        const addrZip = this.$refs.addrZip.value
        const addrRoad = this.$refs.addrRoad.value
        const addrDetail = this.$refs.addrDetail.value
        const etc = this.$refs.etc.value

        await this.api.axiosGql('INSERT_COMPANY', {type:'INSERT_COMPANY', name:name, num:num, ceo:ceo, phone:phone, addrZip:addrZip, addrRoad:addrRoad, addrDetail:addrDetail, etc:etc})
        .then( async (res) => {
          if(res.data === null) this.$notify('다시 시도해주세요.')  
          else this.$notify(res.data.NAME + '이 등록되었습니다.')
        }).catch( (err) => {
          this.$notify('다시 시도해주세요.')
          console.log(err)
        })
      } else if(type === 'INSERT_ITEM') {
        const num = this.company
        const itemName = this.$refs.itemName.value
        const url = this.$refs.url.value
        const imgUrl = this.$refs.imgUrl.value
        const brand = this.$refs.brand.value
        const country = this.$refs.country.value
        const sellPrice = Number(this.$refs.sellPrice.value)
        const originPrice = this.$refs.originPrice.value
        
        await this.api.axiosGql('INSERT_ITEM', {type:'INSERT_ITEM', num:num, name:itemName, url:url, imgUrl:imgUrl, brand:brand, country:country, sellPrice:sellPrice, originPrice:originPrice})
        .then( async (res) => {
          if(res.data === null) this.$notify('다시 시도해주세요.')  
          else this.$notify(res.data.insertItem.NAME + '이 등록되었습니다.')
        }).catch( (err) => {
          this.$notify('다시 시도해주세요.')
          console.log(err)
        })

      }
    },
    async changed() {
      if(this.contractType === 'item') {
        await this.api.axiosGql('SELECT_COMPANY', {type:'SELECT_COMPANY'})
        .then( async (res) => {
          this.companyList = res.data 
        })
      }
    }
  }
}
</script>

<style scoped>
.select-contract {
  width:100%
}
</style>