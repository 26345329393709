<template>
  <div class="div_title" >
    <span style="color: #ff7f50;">주문정보</span>
    <Button label="수정" raised size="small" style="float:right;max-width:50px;" @click="this.$emit('click', {type:'modify-item', value:{id:this.id, idx:this.idx, progress:this.getProgress(), status:this.$refs.oStatus.value, orderNumber:this.$refs.orderNumber.value, clearanceNumber:this.$refs.clearanceNumber.value, trackingNumber:this.$refs.trackingNumber.value, invoiceNumber:this.$refs.invoiceNumber.value, orderType:this.$refs.oOrderType.value, name:this.$refs.name.value, amount:this.$refs.amount.value, sellPrice:this.$refs.sellPrice.value, originPrice:this.$refs.originPrice.value, payPrice:this.$refs.payPrice.value, deliveryPrice1:this.$refs.deliveryPrice1.value, deliveryPrice2:this.$refs.deliveryPrice2.value, url:this.$refs.url.value, imgUrl:this.$refs.imgUrl.value,deliveryForeignCompany:this.$refs.deliveryForeignCompany.value,brand:this.$refs.brand.value,notice:this.$refs.notice.value,msg:this.$refs.msg.value,orderName:this.$refs.orderName.value, phone:this.$refs.phone.value, clearance:this.$refs.clearance.value, addrZip:this.$refs.addrZip.value, addrRoad:this.$refs.addrRoad.value, addrDetail:this.$refs.addrDetail.value, mail:this.mail}})" />
  </div>
  <div>
    <Splitter style="border:none;" >
      <SplitterPanel :minSize="50" >
        <div class="order-status">
          <div >IDX</div>
          <span class="order-count" >
            <input class="normalInput" type="number" :value="idx" disabled/>
          </span>
        </div>
        <div class="order-status">
          <div @click="this.api.copyClipboard(name)" class="link">상품명</div>
          <span class="order-count">
            <input ref="name" class="normalInput" type="text" :value="name" placeholder="상품명"/>
          </span>
        </div>
        <div class="order-status">
          <div @click="this.api.copyClipboard(url)" class="link">URL</div>
          <span class="order-count">
            <input ref="url" class="normalInput" type="url" :value="url" placeholder="URL"/>
          </span>
        </div>
        <div class="order-status">
          <div>수량</div>
          <span class="order-count">
            <input ref="amount" class="normalInput" type="number" :value="amount" placeholder="수량"/>
          </span>
        </div>
        <div class="order-status">
          <div>해외가격</div>
          <span class="order-count">
            <input ref="originPrice" class="normalInput" type="text" :value="originPrice" placeholder="해외가격"/>
          </span>
        </div>
        <div class="order-status">
          <div>통관번호</div>
          <span class="order-count">
            <input ref="clearanceNumber" class="normalInput" type="text" :value="clearanceNumber" placeholder="통관번호"/>
          </span>
        </div>
        <div class="order-status">
          <div @click="this.api.copyClipboard(orderNumber)" class="link">주문번호</div>
          <span class="order-count">
            <input ref="orderNumber" class="normalInput" type="text" :value="orderNumber" placeholder="주문번호"/>
          </span>
        </div>
        <div class="order-status">
          <div @click="this.api.copyClipboard(trackingNumber)" class="link">추적번호</div>
          <span class="order-count">
            <input ref="trackingNumber" class="normalInput" type="text" :value="trackingNumber" placeholder="추적번호"/>
          </span>
        </div>
        <div class="order-status">
          <div @click="this.api.copyClipboard(invoiceNumber)" class="link">송장번호</div>
          <span class="order-count">
            <input ref="invoiceNumber" class="normalInput" type="text" :value="invoiceNumber" placeholder="송장번호"/>
          </span>
        </div>
        <div class="order-status">
          <div>기타</div>
          <span class="order-count">
            <input ref="notice" class="normalInput" type="text" :value="notice" placeholder="기타"/>
          </span>
        </div>
      </SplitterPanel>
      <SplitterPanel :minSize="50" >
        <div class="order-status">
          <div>진행상태</div>
          <span class="order-count">
            <select ref="oStatus" class="normalInput">
              <option v-if="this.api.getOrderStatus(this.status) === '주문접수'" selected value=0>주문접수</option>
              <option v-else value=0>주문접수</option>
              <option v-if="this.api.getOrderStatus(this.status) === '입금대기'" selected value=1>입금대기</option>
              <option v-else value=1>입금대기</option>
              <option v-if="this.api.getOrderStatus(this.status) === '입금완료'" selected value=2>입금완료</option>
              <option v-else value=2>입금완료</option>
              <option v-if="this.api.getOrderStatus(this.status) === '구매완료'" selected value=3>구매완료</option>
              <option v-else value=3>구매완료</option>
              <option v-if="this.api.getOrderStatus(this.status) === '입고대기'" selected value=4>입고대기</option>
              <option v-else value=4>입고대기</option>
              <option v-if="this.api.getOrderStatus(this.status) === '창고도착'" selected value=5>창고도착</option>
              <option v-else value=5>창고도착</option>
              <option v-if="this.api.getOrderStatus(this.status) === '배송중'" selected value=6>배송중</option>
              <option v-else value=6>배송중</option>
              <option v-if="this.api.getOrderStatus(this.status) === '거래완료'" selected value=7>거래완료</option>
              <option v-else value=7>거래완료</option>
              <option v-if="this.api.getOrderStatus(this.status) === '거래취소'" selected value=-1>거래취소</option>
              <option v-else value=-1>거래취소</option>
              <option v-if="this.api.getOrderStatus(this.status) === '환불완료'" selected value=-2>환불완료</option>
              <option v-else value=-2>환불완료</option>
            </select>
          </span>
        </div>
        <div class="order-status">
          <div>대행유형</div>
          <span class="order-count">
            <select ref="oOrderType" class="normalInput">
              <option v-if="this.orderType === '구매대행'" value="구매대행" selected>구매대행</option>
              <option v-else value="구매대행">구매대행</option>
              <option v-if="this.orderType === '배송대행'" value="배송대행" selected>배송대행</option>
              <option v-else value="배송대행">배송대행</option>
            </select>
          </span>
        </div>
        <div class="order-status">
          <div>
            판매가
            <input :id="'sellPriceYn'+idx" class="orderc-chkbox" type="checkbox" :checked="progress.slice(0,1) === '1'" />
          </div>
          <span class="order-count" >
            <!-- <label :for="'sellPriceYn'+idx" style="font-size: 5px;">결제</label> -->
            <input ref="sellPrice" class="normalInput" type="text" :value="sellPrice" placeholder="판매가"/>
          </span>
        </div>
        <div class="order-status">
          <div>
            원가
            <input :id="'payPriceYn' + idx" class="orderc-chkbox" type="checkbox" :checked="progress.slice(1,2) === '1'" />
          </div>
          <span class="order-count">
            <!-- <label :for="'payPriceYn' + idx" style="font-size: 5px;">결제</label> -->
            <input ref="payPrice" class="normalInput" type="text" :value="payPrice" placeholder="원가"/>
          </span>
        </div>
        <div class="order-status">
          <div>
            배대지
            <input :id="'deliveryPrice1Yn'+idx" class="orderc-chkbox" type="checkbox" :checked="progress.slice(2,3) === '1'"/>
          </div>
          <span class="order-count">
            <!-- <label :for="'deliveryPrice1Yn'+idx" style="font-size: 5px;">결제</label> -->
            <input ref="deliveryPrice1" class="normalInput" type="number" :value="deliveryPrice1" placeholder="배대지배송비"/>
          </span>
        </div>
        <div class="order-status">
          <div>
            고객
            <input :id="'deliveryPrice2Yn'+idx" class="orderc-chkbox" type="checkbox" :checked="progress.slice(3,4) === '1'"/>
          </div>
          <span class="order-count">
            <!-- <label :for="'deliveryPrice2Yn'+idx" style="font-size: 5px;">결제</label> -->
            <input ref="deliveryPrice2" class="normalInput" type="number" :value="deliveryPrice2" placeholder="고객배송비"/>
          </span>
        </div>
        <div class="order-status">
        <div>배대지</div>
          <span class="order-count">
            <select ref="deliveryForeignCompany" class="normalInput" >
              <option v-if="this.deliveryForeignCompany === undefined || this.deliveryForeignCompany === ''" selected value=''>선택</option>
              <option v-else value=''>선택</option>
              <option v-if="this.deliveryForeignCompany === '아이포터'" selected value='아이포터'>아이포터</option>
              <option v-else value='아이포터'>아이포터</option>
              <option v-if="this.deliveryForeignCompany === '엘덱스'" selected value='엘덱스'>엘덱스</option>
              <option v-else value='엘덱스'>엘덱스</option>
              <option v-if="this.deliveryForeignCompany === '포스트고'" selected value='포스트고'>포스트고</option>
              <option v-else value='포스트고'>포스트고</option>
              <option v-if="this.deliveryForeignCompany === '솔에어'" selected value='솔에어'>솔에어</option>
              <option v-else value='솔에어'>솔에어</option>
              <option v-if="this.deliveryForeignCompany === '지니집'" selected value='지니집'>지니집</option>
              <option v-else value='지니집'>지니집</option>
              <option v-if="this.deliveryForeignCompany === '월드익스프레스'" selected value='월드익스프레스'>월드익스프레스</option>
              <option v-else value='월드익스프레스'>월드익스프레스</option>
              <option v-if="this.deliveryForeignCompany === '씽배송'" selected value='씽배송'>씽배송</option>
              <option v-else value='씽배송'>씽배송</option>
              <option v-if="this.deliveryForeignCompany === '기타'" selected value='기타'>기타</option>
              <option v-else value='기타'>기타</option>
            </select>
          </span>
        </div>
        <div class="order-status">
          <div @click="this.api.copyClipboard(imgUrl)" class="link">사진</div>
          <span class="order-count">
            <input ref="imgUrl" class="normalInput" type="url" :value="imgUrl" placeholder="사진"/>
          </span>
        </div>
        <div class="order-status">
          <div>사이트</div>
          <span class="order-count">
            <input ref="brand" class="normalInput" type="text" :value="brand" placeholder="브랜드"/>
          </span>
        </div>
        <div class="order-status">
          <div>알림</div>
          <span class="order-count">
            <input ref="msg" class="normalInput" type="text" :value="msg" placeholder="메시지"/>
          </span>
        </div>
      </SplitterPanel>
    </Splitter> 
  </div>
  <div class="div_title">
    <span style="color: #ff7f50;">수취인정보</span>
    <Button label="수정" raised size="small" style="float:right;max-width:50px;" @click="this.$emit('click', {type:'modify-order', value:{idx:this.idx, uid:this.uid, orderName:this.$refs.orderName.value, phone:this.$refs.phone.value, clearance:this.$refs.clearance.value, mail:this.$refs.mail.value, addrZip:this.$refs.addrZip.value, addrRoad:this.$refs.addrRoad.value, addrDetail:this.$refs.addrDetail.value}})"/>
  </div>
  <div class="">
    <Splitter style="border:none;" >
      <SplitterPanel :minSize="50" >
        <div class="order-status">
          <div @click="this.api.copyClipboard(orderName)" class="link">이름</div>
          <span class="order-count">
            <input ref="orderName" class="normalInput" type="text" :value="orderName" placeholder="이름"/>
          </span>
        </div>
        <div class="order-status">
          <div @click="this.api.copyClipboard(clearance)" class="link">통관부호</div>
          <span class="order-count">
            <input ref="clearance" class="normalInput" type="text" :value="clearance" placeholder="통관부호"/>
          </span>
        </div>

      </SplitterPanel>
      <SplitterPanel class="flex items-center justify-center" :minSize="50" >
        <div class="order-status">
          <div @click="this.api.copyClipboard(phone)" class="link">폰번호</div>
          <span class="order-count">
            <input ref="phone" class="normalInput" type="text" :value="phone" placeholder="폰번호"/>
          </span>
        </div>
        <div class="order-status">
          <div @click="this.api.copyClipboard(mail)" class="link">이메일</div>
          <span class="order-count">
            <input ref="mail" class="normalInput" type="text" :value="mail" placeholder="이메일"/>
          </span>
        </div>
      </SplitterPanel>
    </Splitter>
    <div class="order-status">
      <div>주소</div>
      <input ref="addrZip" class="normalInput" type="text" :value="addrZip" placeholder="우편번호" style="margin-right:5px;width:40%;"/>
      <input ref="addrRoad" class="normalInput" type="text" :value="addrRoad" placeholder="도로명주소" style="margin-right:5px;"/>
      <input ref="addrDetail" class="normalInput" type="text" :value="addrDetail" placeholder="상세주소" style="width:50%;"/>
    </div>
    <div style="text-align:right;">
      <span v-if="!(fileName === undefined || fileName === '' || fileName === null)">첨부파일있음</span>
      <input type="file" id="clientInputFile" @change="readFile($event)" style="padding:5px;"/>
    </div>
  </div>
  <div class="div_title">
    <span style="color: #ff7f50;">비용상세</span>
    <span>
      <Button label="수정" raised size="small" @click="this.$emit('click', {type:'UPSERT_ORDER_PRICE_DTL', value:{type:'UPSERT_ORDER_PRICE_DTL', uid:this.uid, picturePrice:this.picturePrice, sitePrice:this.sitePrice, arrivalPrice:this.arrivalPrice, forwarderPrice:this.forwarderPrice, packagePrice:this.packagePrice, storagePrice:this.storagePrice, consumerPrice:this.consumerPrice, refundPrice:this.refundPrice, clearancePrice:this.clearancePrice }})" style="margin-right:5px;"/>
      <Button label="조회" raised size="small" @click="click({type:'SELECT_ORDER_PRICE_DTL', uid:this.uid })" />
    </span>
  </div>
  <div>
    <Splitter style="border:none;" >
      <SplitterPanel :minSize="50" >
        <div class="order-status">
          <div>사진촬영</div>
          <span class="order-count">
            <input v-model="picturePrice" class="normalInput" type="text" placeholder="사진촬영비"/>
          </span>
        </div>
        <div class="order-status">
          <div>착불</div>
          <span class="order-count">
            <input v-model="arrivalPrice" class="normalInput" type="text" placeholder="착불비"/>
          </span>
        </div>
        <div class="order-status">
          <div>포장보완</div>
          <span class="order-count">
            <input v-model="packagePrice" class="normalInput" type="text" placeholder="추가포장"/>
          </span>
        </div>
        <div class="order-status">
          <div>소비자변심</div>
          <span class="order-count">
            <input v-model="consumerPrice" class="normalInput" type="text" placeholder="기본수수료"/>
          </span>
        </div>
        <div class="order-status">
          <div>세관</div>
          <span class="order-count">
            <input v-model="clearancePrice" class="normalInput" type="text" placeholder="언더밸류/통관/관부가세"/>
          </span>
        </div>
      </SplitterPanel>
      <SplitterPanel class="flex items-center justify-center" :minSize="50" >
        <div class="order-status">
          <div>해외수수료</div>
          <span class="order-count">
            <input v-model="sitePrice" class="normalInput" type="text" placeholder="해외판매자수수료"/>
          </span>
        </div>
        <div class="order-status">
          <div>포워더</div>
          <span class="order-count">
            <input v-model="forwarderPrice" class="normalInput" type="text" placeholder="포워더수수료"/>
          </span>
        </div>
        <div class="order-status">
          <div>보관비</div>
          <span class="order-count">
            <input v-model="storagePrice" class="normalInput" type="text" placeholder="보관비"/>
          </span>
        </div>
        <div class="order-status">
          <div>환불</div>
          <span class="order-count">
            <input v-model="refundPrice" class="normalInput" type="text" placeholder="반송비"/>
          </span>
        </div>
      </SplitterPanel>
    </Splitter>
  </div>
</template>
<script>
export default {
  mounted() {
    this.click({type:'SELECT_ORDER_PRICE_DTL', uid:this.uid })
  }, 
  data() { 
    return { 
      clientFileName:'',
      storagePrice:'0',
      packagePrice:'0',
      picturePrice:'0',
      sitePrice:'0',
      arrivalPrice:'0',
      forwarderPrice:'0',
      consumerPrice:'0',
      refundPrice:'0',
      clearancePrice:'0',
    }
  },
  props: {
    idx: Number,
    id: String,
    name: String,
    fileName: String,
    progress: String,     // <!-- PROGRESS진행상황(소비자결제1/구매완료/배대지결제/소비자결제2) -->
    clearance: String,
    addrRoad: String,
    deliveryKrCompany: String,
    deliveryForeignCompany: String,
    addrDetail: String,
    uid: String,
    notice: String,
    mail: String,
    clearanceNumber: String,
    invoiceNumber: String,
    trackingNumber: String,
    orderNumber: String,
    orderType: String,
    addrZip: String,
    orderName: String,
    status: Number,
    phone: String,
    url: String,
    amount: Number,
    brand: String,
    deliveryPrice1: Number,
    deliveryPrice2: Number,
    sellPrice: Number,
    originPrice: String,
    country: String,
    orderDate: String,
    payPrice: Number,
    imgUrl: String,
    msg: String
  },
  methods: {
    getProgress() {
      const id0 = 'sellPriceYn' + this.idx
      const id1 = 'payPriceYn' + this.idx
      const id2 = 'deliveryPrice1Yn' + this.idx
      const id3 = 'deliveryPrice2Yn' + this.idx
      let progress = ''  
      document.getElementById(id0).checked ? progress += '1' : progress += '0'  
      document.getElementById(id1).checked ? progress += '1' : progress += '0'  
      document.getElementById(id2).checked ? progress += '1' : progress += '0'  
      document.getElementById(id3).checked ? progress += '1' : progress += '0'  
      
      return progress
    },
    async readFile(event) {
      this.file = event.target.files[0]

      if(this.file.size >= 3072000) alert('3M 이하 파일만 첨부가 가능합니다')
      else {
        const type = 'UPLOAD_ORDER_FILE'
        const formData = new FormData()
        formData.append("file", this.file)
        formData.append('service', 'geek9')
        formData.append('pbType', 'order')
        formData.append('type', type)
        await this.api.axiosGql(type, formData)
        .then( async (res) => {
          this.clientFileName = res.data
          if(this.clientFileName !== '') {
            this.api.axiosGql('UPDATE_ORDER_FILE', {type:'UPDATE_ORDER_FILE', fileName:this.clientFileName, idx:this.idx, mail:this.mail, name:this.name, url:this.url})
            this.$notify('첨부완료')
          }
        })
      }  
    },
    async click(param) {
      const type = param.type
      if(type === 'SELECT_ORDER_PRICE_DTL') {
        const uid = param.uid
        await this.api.axiosGql(type, {type:type, uid:uid})
        .then( async (res) => {
          if(res.status === 200) {
            const json = res.data  
            if(!(json === null || json === undefined)) {
              this.storagePrice = json.STORAGE_PRICE
              this.packagePrice = json.PACKAGE_PRICE
              this.picturePrice = json.PICTURE_PRICE
              this.sitePrice = json.SITE_PRICE
              this.arrivalPrice = json.ARRIVAL_PRICE
              this.forwarderPrice = json.FORWARDER_PRICE
              this.consumerPrice = json.CONSUMER_PRICE
              this.refundPrice = json.REFUND_PRICE
              this.clearancePrice = json.CLEARANCE_PRICE
            }
          }
        })
      }
    }
  }
}
</script>

<style scoped>
.orderc-chkbox {
  width:fit-content;
  vertical-align: middle;
}
.order-status {
  display:flex;
  margin-top: 15px;
  margin-bottom: 15px;
}
.order-status > div {
  margin-top:5px;
  min-width:110px;
  text-align:center;
}
.order-count {
  width:100%;
  margin-right:10px;
}
</style>