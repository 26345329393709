<template>
  <Tabs value="0">
    <TabList>
      <Tab value="0">스토어</Tab>
      <Tab value="1">깃츠</Tab>
      <Tab value="2">카페</Tab>
    </TabList>
    <TabPanels>
      <TabPanel value="0">
        <urlStore/>
      </TabPanel>
      <TabPanel value="1">
        <shopStore />
      </TabPanel>
      <TabPanel value="2">
        <cafeStore />
      </TabPanel>  
    </TabPanels>
  </Tabs>
</template>
<script>
import shopStore from '@/components/store/shopStore.vue'
import urlStore from '@/components/store/urlStore.vue'
import cafeStore from '@/components/store/cafeStore.vue'

export default {
  setup() {
  },
  components: {
    urlStore, shopStore, cafeStore
  },  
  mounted() {
  },
  data() { 
    return {
    }
  },
  methods: {
  }
}
</script>
