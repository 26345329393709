<template>
  <br><br><br>
  <div v-if="this.api.getCookies('id') === this.kakao_id" :key='dashboardKey' >
    <div class="tab" style="overflow:hidden;border:1px solid #ff7f50;">
      <button class="tablinks" @click="tab($event, 'stock')">Stock</button>
      <button class="tablinks" @click="tab($event, 'solair')">Solair</button>
      <button class="tablinks active" @click="tab($event, 'order')">Order</button>
      <button class="tablinks" @click="tab($event, 'fulfillment')">3PL</button>
      <button class="tablinks" @click="tab($event, 'todo')">ToDo</button>
      <button class="tablinks" @click="tab($event, 'contract')">Contract</button>
      <button class="tablinks" @click="tab($event, 'notice')">Code</button>
      <button class="tablinks" @click="tab($event, 'mail')">Mail</button>
      <button class="tablinks" @click="tab($event, 'qa')">Bot</button>
      <!-- <button class="tablinks" @click="tab($event, 'hot')">Hot</button> -->
      <!-- <button class="tablinks" @click="tab($event, 'nlp')">NLP</button> -->
      <button class="tablinks" @click="tab($event, 'statistics')">Statistics</button>
      <button class="tablinks" @click="tab($event, 'board')">Contents</button>
      <button class="tablinks" @click="tab($event, 'store')">Store</button>
      <!-- <button class="tablinks" @click="tab($event, 'hr')">HR</button> -->
    </div>
    
    <div id="stock" class="tabcontent" >
      <table class="table" style="width:100%;">
        <tr style="font-weight: bold;">
          <td>
            <span style="color: #ff7f50;">현황</span> 
          </td>
          <td></td>
          <td></td>
          <td>
            <input style="max-width:50px;float:right;" type="button" v-on:click="click('SELECT_ALL_STOCK_PROFIT')" value="조회" />
          </td>
        </tr>
        <tr style="height:40px;text-align: center;margin-top: 10px;">
          <td style="width:15%"></td>
          <td style="width:25%;background-color: aliceblue;">보유(주)</td>
          <td style="width:30%;text-align: center;background-color: aliceblue;">수익(원)</td>
          <td style="width:30%;text-align: center;background-color: aliceblue;">평가(원)</td>
        </tr>
        <tr style="height:40px;text-align: center;">
          <td style="background-color: aliceblue;">삼성</td>
          <td ref="samsung_cnt" style="font-style: italic;">-</td>
          <td ref="samsung_profit" style="font-style: italic;">-</td>
          <td ref="samsung_total" style="font-style: italic;">-</td>
        </tr>
        <tr style="height:40px;text-align: center;">
          <td style="background-color: aliceblue;">미래</td>
          <td ref="mirae_cnt" style="font-style: italic;">-</td>
          <td ref="mirae_profit" style="font-style: italic;">-</td>
          <td ref="mirae_total" style="font-style: italic;">-</td>
        </tr>
        <tr style="height:40px;text-align: center;">
          <td style="background-color: aliceblue;">한국</td>
          <td ref="hankook_cnt" style="font-style: italic;">-</td>
          <td ref="hankook_profit" style="font-style: italic;">-</td>
          <td ref="hankook_total" style="font-style: italic;">-</td>
        </tr>
      </table>
      <br>
      <table class="table">
        <tr style="font-weight: bold;">
          <span style="color: #ff7f50;">관리</span> 
          <td colspan="6" ref="desc" style="text-align: right;padding-bottom: 10px;" >Stock만 Insert</td>
        </tr>
        <tr>
          <td class="padding-left" >
            <select ref="stock_broker" style="width:100%" class="essential essentialy">  
              <option value = "삼성증권">삼성증권</option>
              <option value = "미래에셋증권">미래에셋</option>
              <option value = "한국투자증권">한국투자</option>
            </select>
          </td>
          <td class="padding-left" >
            <select ref="stock_is_sell" style="width:100%" class="essential essentialy"> 
              <option value = "buy">매수</option>
              <option value = "sell">매도</option>
            </select>
          </td>
          <td class="padding-left">
            <input ref='stock_date' type="number" placeholder="거래일" />
          </td>
          <td></td>
          <td></td>
          <td class="padding-left">
            <select ref="work_type" @change="typeChanged(0)" style="width:100%">  
              <option value = "InsertStockOnly">InsertStockOnly</option>
              <option value = "UpdatePossessOnly">UpdatePossessOnly</option>
              <option value = "UpsertStock&Possess">UpsertStock&Possess</option>
              <option value = "Delete">Delete</option>
            </select>
          </td>
          <td class="padding-left">
            <input type="button" v-on:click="click('실행')" value="실행" />
          </td>
        </tr>
        <tr style="width:100%">
          <td class="padding-left" >
            <input ref='stock_code' type="text" placeholder="종목코드" class="essential essentialy" @keyup="codeInserted()"/>
          </td>
          <td class="padding-left" >
            <input ref='stock_name' type="text" placeholder="종목명" class="essential essentialy"/>
          </td> 
          <td class="padding-left" >
            <input ref='stock_price' type="number" placeholder="체결가" class="essential essentialy"/>
          </td> 
          <td class="padding-left" >
            <input ref='stock_amount' type="number" placeholder="체결수량" class="essential essentialy"/>
          </td> 
        </tr>
      </table>
      <br>
      <table class="table" style="width:100%;">
        <tr style="font-weight: bold; width:100%">
          <span style="color: #ff7f50;">조회</span> 
          <label style="color: blue;"> ({{totalCnt}}건)</label>

          <td style="float:right;">
            <select ref="condition" @change="typeChanged(1)">  
              <option value = "opt0">종목</option>
              <option value = "opt1">목표#1</option>
              <option value = "opt2">목표#2</option>
              <option value = "opt3">목표#3</option>
              <option value = "opt7">목표#4</option>
              <option value = "opt4">체결일</option>
              <option value = "opt5">미결제</option>
              <option value = "opt6">ETF</option>
            </select>
          </td>
        </tr> 
      </table>
      <table class="table" style="width:100%;">
        <tr>
          <td class="padding-left">
            <select ref="select_broker" class="search_select"> 
              <option value = "all">증권사전체</option>
              <option value = "삼성증권">삼성증권</option>
              <option value = "미래에셋증권">미래에셋</option>
              <option value = "한국투자증권">한국투자</option>
            </select>
            <select ref="select_day" class="search_select" style="display: none;">  
              <option value = "custom">직접입력</option>
            </select>
            <select ref="select_range" class="search_select" style="display: none;">  
              <option value = "range_tmp1">range_tmp1</option>
              <option value = "range_tmp2">range_tmp2</option>
            </select>
          </td>          
          <td colspan="4" style="width:100%;" class="padding-left">
            <input ref='stock_input' type="text" placeholder="종목코드" v-on:click="click('cursor')" v-on:keyup.enter="click('조회')"/>
          </td>
          <td colspan="1" class="padding-left" style="min-width:100px;">
            <input ref='chk_updn' id="chk_updn" type="checkbox" style="max-width: 10px;" checked />
            <label ref='lbl_updn' class="condition-admin" for="chk_updn" style="color: red;">등락</label>
            <input ref='chk_zero' id="chk_zero" type="checkbox" style="max-width: 10px;" />
            <label ref='lbl_zero' class="condition-admin" for="chk_zero" style="color: red;">0주</label>
            <input ref='chk_notice' id="chk_notice" type="checkbox" style="max-width: 10px;" />
            <label ref='lbl_notice' class="condition-admin" for="chk_notice" style="color: red;">비고</label>
            <input ref='chk_desc' id="chk_desc" type="checkbox" style="max-width: 10px;" />
            <label ref='lbl_desc' class="condition-admin" for="chk_desc" >정렬</label>
           </td>
          <td class="padding-left">
            <input ref='search_stock' v-on:click="click('조회')" type="button" value="조회" />
          </td>
          <td class="padding-left">
            <input type="button" v-on:click="click('EXECUTE_FORCE')" value="강제" />
          </td>
        </tr>
      </table>
      
      <div v-if="stockList.length === 0 && this.stockPage === 1" class="no-result"> 
          조회 내역이 없습니다.
      </div>
      <div v-else style="text-align:center">
        <table style="width:100%;">
          <!-- add table header -->
          <tr style="font-weight: bold;">
            <td style="text-align: center;max-width: 12px;min-width:12px;">종목명</td>
            <td style="text-align: left;max-width: 12px;min-width:12px;">증권사</td>
            <td style="text-align: left;">보유량</td>
            <td style="text-align: right;max-width: 12px;min-width:12px;">현재가</td>
            <td style="text-align: right;max-width: 12px;min-width:12px;">수익률</td>
          </tr>
        </table>
        <div v-for="(stock, index) in stockList" :key="index" >
          <table style="width:100%;">
            <tr v-if="stock.PRES_PRICE === null || stock.PRES_PRICE === undefined" >
              <button class="collapsible" v-if="stock.IS_SELL === true" @click="this.api.copyClipboard(stock.CODE)"> 
                <label style="color: red;">{{stock.NAME}} &nbsp;|&nbsp; {{stock.CODE}} &nbsp;|&nbsp; {{stock.BROKER}} &nbsp;|&nbsp; {{Number(stock.PRICE).toLocaleString()}}원 &nbsp;|&nbsp; {{stock.AMOUNT}}주 매도</label> 
              </button>
              <button class="collapsible" v-else @click="this.api.copyClipboard(stock.CODE)"> 
                <label style="color: blue;">{{stock.NAME}} &nbsp;|&nbsp; {{stock.CODE}} &nbsp;|&nbsp; {{stock.BROKER}} &nbsp;|&nbsp; {{Number(stock.PRICE).toLocaleString()}}원 &nbsp;|&nbsp; {{stock.AMOUNT}}주 매수</label> 
              </button>
            </tr>
            <tr v-else-if="stock.IS_SELL === true">
              <button class="collapsible text-cut" @click="collapse({idx:index, code:stock.CODE})" >  
                <label class="subInfoFont" v-if="stock.TYPE_COLOR === 'red'" style="float:left;margin-right:10px;font-weight: bolder; color:red; background-color: orange;">※</label>
                <label class="subInfoFont" v-else-if="stock.TYPE_COLOR === 'blue'" style="float:left;margin-right:10px;font-weight: bolder; color:blue; background-color: orange;">※</label>
                <label class="subInfoFont" v-else style="float:left;margin-right:10px;font-weight: bolder; background-color: orange; color:white;" >※</label>
                <label>{{stock.NAME}} &nbsp;|&nbsp; {{stock.BROKER}} &nbsp;|&nbsp; {{stock.AMOUNT}}주 &nbsp;|&nbsp; {{stock.CONTENT}}</label> 
                <label class="infoFont" v-if="stock.PROFIT_RATE > 0" style="float:right;color:red;">{{stock.PRES_PRICE.toLocaleString()}}원 &nbsp;|&nbsp; {{stock.PROFIT_RATE.toFixed(2)}}% </label>
                <label class="infoFont" v-else-if="stock.PROFIT_RATE < 0" style="float:right;color:blue;">{{stock.PRES_PRICE.toLocaleString()}}원 &nbsp;|&nbsp; {{stock.PROFIT_RATE.toFixed(2)}}% </label>
                <label class="infoFont" v-else style="float:right;">{{stock.PRES_PRICE.toLocaleString()}}원 &nbsp;|&nbsp; {{stock.PROFIT_RATE}}% </label>
              </button>
            </tr>
            <tr v-else>
              <button class="collapsible text-cut" @click="collapse({idx:index, code:stock.CODE})" >  
                <label class="subInfoFont" v-if="stock.TYPE_COLOR === 'red'" style="float:left;margin-right:10px;font-weight: bolder; color:red;">※</label>
                <label class="subInfoFont" v-else-if="stock.TYPE_COLOR === 'blue'" style="float:left;margin-right:10px;font-weight: bolder; color:blue;">※</label>
                <label class="subInfoFont" v-else style="float:left;margin-right:10px;font-weight: bolder; color:white;">※</label>
                <label>{{stock.NAME}} &nbsp;|&nbsp; {{stock.BROKER}} &nbsp;|&nbsp; {{stock.AMOUNT}}주 &nbsp;|&nbsp; {{stock.CONTENT}}</label> 
                <label class="infoFont" v-if="stock.PROFIT_RATE > 0" style="float:right;color:red;">{{stock.PRES_PRICE.toLocaleString()}}원 &nbsp;|&nbsp; {{stock.PROFIT_RATE.toFixed(2)}}% </label>
                <label class="infoFont" v-else-if="stock.PROFIT_RATE < 0" style="float:right;color:blue;">{{stock.PRES_PRICE.toLocaleString()}}원 &nbsp;|&nbsp; {{stock.PROFIT_RATE.toFixed(2)}}% </label>
                <label class="infoFont" v-else style="float:right;">{{stock.PRES_PRICE}}원 &nbsp;|&nbsp; {{stock.PROFIT_RATE}}% </label>
              </button>
            </tr>
          </table>
          <stockc @collapse="collapse" v-if="stock.PRES_PRICE !== null && stock.PRES_PRICE !== undefined" class="content" @setRecentInfo="setRecentInfo" @modify="modify" :content=stock.CONTENT :presPrice=stock.PRES_PRICE :idx=index :insertAmount="stock.INSERT_AMOUNT" :recentPrice="stock.RECENT_PRICE" :date="stock.DATE" :isSell="stock.IS_SELL" :name="stock.NAME" :broker="stock.BROKER" :code="stock.CODE" :amount="stock.AMOUNT" :nextBuyPrice="stock.NEXT_BUY_PRICE" :nextSellPrice="stock.NEXT_SELL_PRICE" :price="stock.PRICE" :profitModTime="stock.PROFIT_MOD_TIME" :profitRate="stock.PROFIT_RATE" :key="stockcKey"/>
        </div>
        <br>
        <label @click="paging('prev')" class="link">
          이전
        </label>
        <span style="margin:20px">{{stockPage}}</span>
        <label @click="paging('next')" class="link">
          다음
        </label>
      </div>
    </div>

    <span id="solair" class="tabcontent"><solair /></span>
    <span id="notice" class="tabcontent"><notice /></span>
    <span id="qa" class="tabcontent"><qa /></span>
    <span id="contract" class="tabcontent"><contract /></span>
    <span id="mail" class="tabcontent"><mail /></span>
    <span id="order" class="tabcontent" style="display: block;">
      <order />
    </span>
    <span id="statistics" class="tabcontent">
      <statistics />
    </span>
    <span id="fulfillment" class="tabcontent">
      <fulfillment />
    </span>
    <span id="todo" class="tabcontent">
      <todo />
    </span>
    <span id="store" class="tabcontent">
      <store />
    </span>
    <span id="board" class="tabcontent">
      <Tabs value="0">
        <TabList>
          <Tab value="0">BOARD</Tab>
          <Tab value="2">TRADE</Tab>
          <Tab value="1">글쓰기</Tab>
        </TabList>
        <TabPanels>
          <TabPanel value="0">
            <read page='admin'/>
          </TabPanel>
          <TabPanel value="1">
            <write :page="this.api.getCookies('page')" @login="login"/>
          </TabPanel>
          <TabPanel value="2">
            <read page='trade'/>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </span>
  </div>
  <div v-else v-on="reload('home')"></div>
  <div class="ad-container">
    <Adsense
        data-ad-client="ca-pub-6699354447815429" 
        data-ad-slot="8230887469"
        data-ad-format="horizontal"
        data-full-width-responsive="true">
    </Adsense>
    <InArticleAdsense
        data-ad-client="ca-pub-6699354447815429" 
        data-ad-slot="8230887469"
        data-ad-format="horizontal"
        data-full-width-responsive="true">
    </InArticleAdsense>
    <InFeedAdsense
        data-ad-client="ca-pub-6699354447815429" 
        data-ad-slot="8230887469"
        data-ad-format="horizontal"
        data-full-width-responsive="true">
    </InFeedAdsense>
  </div>
  <adminmenu @view="view" />
</template>

<script>
require('dotenv').config()
import router from './router'
import { ref } from 'vue'
import stockc from '@/components/content/stock_content.vue'
import adminmenu from '@/components/admin/admin_menu.vue'
import notice from '@/components/admin/notice.vue'
import contract from '@/components/admin/contract.vue'
import mail from '@/components/admin/mail.vue'
import solair from '@/components/admin/solair.vue'
import qa from '@/components/admin/qa.vue'
import statistics from '@/components/admin/statistics.vue'
import fulfillment from '@/components/admin/fulfillment.vue'
import todo from '@/components/admin/todo.vue'
import store from '@/components/admin/store.vue'
import write from '@/Write.vue'
import read from '@/Read.vue'
import order from '@/components/admin/order.vue'
var items

export default {
  emits: ["setCookies", "setMenu"],
  components: {
    stockc, adminmenu, notice, contract, solair, statistics, order, fulfillment, mail, todo, qa, write, read, store
  },  
  mounted() {
    this.emitter.on('adminReload', this.reload)
    this.api.setPage('admin')
    this.$emit("setMenu", {page:'admin'})
    if(this.api.getCookies('id') === this.kakao_id ) console.log(this.api.getCookies('page'))
    else router.push({ name: 'home'})
    window.scrollTo(0, 0)
  },
  unmounted() {
    this.emitter.off('adminReload', this.reload)
  },
  setup() {
    items = ref([])
    return {
      items,
    }
  },
  data() { 
    return { 
      kakao_id: process.env.VUE_APP_KAKAO_ID,
      auto_reload_func: null,
      radio: 'BROKER',
      per: 0,
      type: '',
      stockPage: 1,
      dashboardKey: 0,
      stockList: Array(),
      totalCnt:0,
      stockcKey:-1
    }
  },
  methods: {
    login() {
      this.$emit('login')
    },
    view(param) {
      this.$refs.select_broker.value = 'all'
      this.$refs.condition.value = 'opt0' 
      this.$refs.stock_input.value = param.code
      this.$refs.chk_updn.checked = true
      this.$refs.chk_zero.checked = true
      this.click('조회')
    },
    async codeInserted() {
      const code = this.$refs.stock_code.value
      if(code.length > 5) {
        await this.api.axiosGql('SELECT_STOCK_BY_CODE', {type:'SELECT_STOCK_BY_CODE',code: code, broker:''})
        .then( async (result) => {
          const json = result.data  
          if(json !== undefined && json.length !== 0) {
            this.$refs.stock_name.value = json[0].NAME 
          }
        })
      }
    },
    collapse(param) {
      const sub_idx = param.idx
      let code = param.code
      const sub_menu_list = document.getElementById("stock")
      let col_btn_list = sub_menu_list.getElementsByTagName("button")
      let col_con_list = sub_menu_list.getElementsByClassName("content")

      const btn = col_btn_list[sub_idx]
      const con = col_con_list[sub_idx]
      
      if(con === undefined) {
        this.collapseAll()
        this.paging('next')
        const el = document.getElementById('stock')
        el.scrollIntoView()
      } else {
        // open
        const btnClass = btn.getAttribute("class") 
        if(btnClass === 'collapsible select' || btnClass === 'collapsible select text-cut') {
          btn.setAttribute("class", "collapsible text-cut")
          btn.style.color = "black"
          btn.style.fontWeight = ""
          con.style.maxHeight = null
        } else {    // close
          this.collapseAll()
          btn.setAttribute("class", "collapsible select text-cut")
          btn.style.fontWeight = "bold"
          con.style.maxHeight = con.scrollHeight + "px"
          this.emitter.emit('stockRefresh', {idx:sub_idx})
          if(code === '') {
            code = con.querySelector('#selectedCode').innerHTML
          } 
          this.api.copyClipboard(code)
        }
      }
    },
    collapseAll() {
      const sub_menu_list = document.getElementById("stock")
      let col_btn_list = sub_menu_list.getElementsByTagName("button")
      let col_con_list = sub_menu_list.getElementsByClassName("content")
      
      for(var i = 0; i < col_btn_list.length; i++) {
        let colBtn = col_btn_list[i]
        if(colBtn !== undefined) {
          colBtn.setAttribute("class", "collapsible text-cut")
          colBtn.style.color = "black"
          colBtn.style.fontWeight = ""
        } 
        let tmpCon = col_con_list[i]
        if(tmpCon !== undefined ) tmpCon.style.maxHeight = null
      }
    },
    typeChanged(type) {
      if(type === 0) {
        let essentialList = document.querySelectorAll('.essential')
        if(essentialList.leng !== 0) {
          for (let i = 0; i < essentialList.length; i++) {
            essentialList[i].setAttribute('class', 'essential')
          }
        } 
        const workType = this.$refs.work_type.value
        essentialList[0].setAttribute('class', 'essential essentialy')
        essentialList[2].setAttribute('class', 'essential essentialy')
        essentialList[3].setAttribute('class', 'essential essentialy')
        essentialList[4].setAttribute('class', 'essential essentialy')
        essentialList[5].setAttribute('class', 'essential essentialy')
          
        if(workType === 'InsertStockOnly') {
          essentialList[1].setAttribute('class', 'essential essentialy')
          this.$refs.desc.innerText = 'Stock만 Insert'
        } else if(workType === 'UpsertStock&Possess') {
          essentialList[1].setAttribute('class', 'essential essentialy')
          this.$refs.desc.innerText = 'Stock/Possess 모두 Upsert'
        } else if(workType === 'UpdatePossessOnly') {
          this.$refs.desc.innerText = 'Possess만 Upsert'
        } else if(workType === 'Delete') {
          essentialList[1].setAttribute('class', 'essential')
          essentialList[3].setAttribute('class', 'essential')
          essentialList[4].setAttribute('class', 'essential')
          essentialList[5].setAttribute('class', 'essential')
          this.$refs.desc.innerText = '종목 삭제'
        }
      } else if(type === 1) {
        document.getElementsByClassName('search_select')[0].style.display='none'    // BROKER
        document.getElementsByClassName('search_select')[1].style.display='none'    // DAY
        document.getElementsByClassName('search_select')[2].style.display='none'    // RANGE
        const condition = this.$refs.condition.value 
        this.setLabelColor(condition)
        if(condition === 'opt1' || condition === 'opt2' || condition === 'opt3' || condition === 'opt5' || condition === 'opt6') {
          this.$refs.stock_input.value = ''
          this.$refs.stock_input.placeholder = '종목코드'
          document.getElementsByClassName('search_select')[0].style.display=''
        } else if(condition === 'opt4') {
          this.$refs.stock_input.placeholder = 'YYYYMMDD'
          let ms = Number(Date.parse(Date())) / 1000
          this.$refs.stock_input.value = this.api.getDate(ms, 'YYYYMMDD')
          document.getElementsByClassName('search_select')[1].style.display=''
        } else if(condition === 'RANGE') {
          this.$refs.stock_input.value = ''
          document.getElementsByClassName('search_select')[2].style.display=''
        } else if(condition === 'opt0') {
          this.$refs.stock_input.value = ''
          this.$refs.stock_input.placeholder = '종목코드'
          document.getElementsByClassName('search_select')[0].style.display=''
        }
      }
    },
    setLabelColor(type) {
      this.$refs.lbl_updn.style.color = 'red'
      this.$refs.lbl_zero.style.color = 'red'
      this.$refs.lbl_notice.style.color = 'red'
      this.$refs.lbl_desc.style.color = 'black'
      if(type === 'opt1' || type === 'opt7') this.$refs.lbl_desc.style.color = 'red'
      else if(type === 'opt3') this.$refs.lbl_updn.style.color = 'black'
      else if(type === 'opt4' || type === 'opt5' || type === 'opt6') {
        this.$refs.lbl_updn.style.color = 'black'
        this.$refs.lbl_zero.style.color = 'black'
        this.$refs.lbl_notice.style.color = 'black'
      }
    },
    async getAllStockProfit() {
      await this.api.axiosGql('SELECT_ALL_STOCK_PROFIT', {type:'SELECT_ALL_STOCK_PROFIT'})
      .then( async (result) => {
        let json = result.data
        if( json.length !== 0) {
          json = json[0]
          const samTotal1 = Number(json.SAMSUNG_TOTAL1)
          const samTotal2 = Number(json.SAMSUNG_TOTAL2)
          const miraeTotal1 = Number(json.MIRAE_TOTAL1)
          const miraeTotal2 = Number(json.MIRAE_TOTAL2)
          const hanTotal1 = Number(json.HANKOOK_TOTAL1)
          const hanTotal2 = Number(json.HANKOOK_TOTAL2)

          this.$refs.samsung_cnt.innerHTML    = Number(json.SAMSUNG_CNT).toLocaleString()
          this.$refs.samsung_profit.innerHTML = (samTotal2 - samTotal1).toLocaleString()
          this.$refs.hankook_cnt.innerHTML    = Number(json.HANKOOK_CNT).toLocaleString()
          this.$refs.hankook_profit.innerHTML = (hanTotal2 - hanTotal1).toLocaleString()
          this.$refs.mirae_cnt.innerHTML      = Number(json.MIRAE_CNT).toLocaleString()
          this.$refs.mirae_profit.innerHTML   = (miraeTotal2 - miraeTotal1).toLocaleString()
          this.$refs.samsung_total.innerHTML  = Number(json.SAMSUNG_TOTAL1).toLocaleString()
          this.$refs.hankook_total.innerHTML  = Number(json.HANKOOK_TOTAL1).toLocaleString()
          this.$refs.mirae_total.innerHTML    = Number(json.MIRAE_TOTAL1).toLocaleString()
          
        }
      })
    },
    async click(stockType) {
      const insertBroker = this.$refs.stock_broker.value
      const insertIsSell = this.$refs.stock_is_sell.value === 'sell' ? true : false
      const insertName   = this.$refs.stock_name.value
      const insertCode   = this.$refs.stock_code.value
      const insertAmount = Number(this.$refs.stock_amount.value)
      const insertPrice  = Number(this.$refs.stock_price.value)
      const insertDate   = this.$refs.stock_date.value
      
      if(stockType === '실행')  {
        stockType = this.$refs.work_type.value
        if(stockType === 'InsertStockOnly') {
          if( insertName === '' || insertCode === '' || insertAmount < 0 || insertPrice < 0) this.$notify('누락된 값이 있습니다')
          else {
            await this.api.axiosGql('INSERT_STOCK', {type:'INSERT_STOCK',price: insertPrice, amount: insertAmount, code: insertCode, name: insertName, broker: insertBroker, isSell:insertIsSell, date: insertDate})
            .then( async (result) => {
              if(result === null || result === undefined) {
                this.$notify('등록이 되지 않았습니다')
              } else {
                this.$notify(result.data.NAME + ' 종목이 등록 되었습니다 : ')
              }
            })
          }
        } else if(stockType === 'UpsertStock&Possess') {
          await this.api.axiosGql('SELECT_STOCK_BY_CODE', {type:'SELECT_STOCK_BY_CODE', code: insertCode, broker: insertBroker})
          .then( async (possessResult) => {
            const possessStock = possessResult.data
            if( possessStock.length === 0) {
              await this.api.axiosGql('INSERT_STOCK', {type:'INSERT_STOCK',price: insertPrice, amount: insertAmount, code: insertCode, name: insertName, broker: insertBroker, isSell:insertIsSell, date: insertDate})
              .then( async (result) => {
                if(result === null || result === undefined) this.$notify('등록이 되지 않았습니다')
                else {
                  await this.api.calcNextPrice(result.data, -1)
                  .then(async (result) => {
                    if(result === null || result === undefined) {
                      this.$notify('등록이 되지 않았습니다')
                    } else {
                      result.type = 'INSERT_STOCK_POSSESS'
                      await this.api.axiosGql('INSERT_STOCK_POSSESS', result)
                      .then( async () => {
                        this.$notify('등록이 되었습니다')
                      }).catch( (err) => {
                        console.log(err)
                        this.$notify('등록이 되지 않았습니다')
                      })
                    }
                  })
                }
              })
            } else {
              await this.api.axiosGql('INSERT_STOCK', {type:'INSERT_STOCK',price: insertPrice, amount: insertAmount, code: insertCode, name: insertName, broker: insertBroker, isSell:insertIsSell, date: insertDate})
              .then( async (result) => {
                if(result === null || result === undefined) this.$notify('등록이 되지 않았습니다')
                else {
                  let possess = possessStock[0]
                  let possessAmount = possess.AMOUNT
                  let possessPrice = possess.PRICE
                  if(insertIsSell === true) possess.AMOUNT -= insertAmount
                  else {
                    let oldTotalPrice = possessPrice * possessAmount                        // 기존 총액
                    let newTotalPrice = insertPrice * insertAmount                          // 신규 총액
                    let newAmount = possessAmount + insertAmount                            // 신규 수량
                    possess.PRICE = Math.round((oldTotalPrice + newTotalPrice) / newAmount) // 신규 평균가
                    possess.AMOUNT = newAmount                                              // 신규 수량
                  }
                  await this.api.calcNextPrice(possess, insertPrice)
                  .then(async (result) => {
                    if(result === null || result === undefined) {
                      console.log('등록이 되지 않았습니다')
                    } else {
                      result.date = insertDate
                      result.type = 'INSERT_STOCK_POSSESS'
                      await this.api.axiosGql('INSERT_STOCK_POSSESS', result)
                      .then( async () => {
                        this.$notify('등록되었습니다')
                      }).catch( (err) => {
                        console.log(err)
                        this.$notify('등록이 되지 않았습니다')
                      })
                    }
                  })
                }
              })
            }
          }) 
          this.api.axiosGql('EXECUTE_FORCE', {type:'EXECUTE_FORCE', code: insertCode, broker: insertBroker})
          this.api.axiosGql('DELETE_STOCK_TRADE', {type:'DELETE_STOCK_TRADE', id:-1, code: insertCode, broker: insertBroker})
        } else if(stockType === 'UpdatePossessOnly') {
          await this.api.calcNextPrice({PRICE:0, BROKER:insertBroker}, insertPrice)
          .then(async (result) => {
            if(result === null || result === undefined) {
              this.$notify('등록이 되지 않았습니다')
            } else {
              result.NAME = insertName
              result.AMOUNT = insertAmount
              result.CODE = insertCode
              result.PRICE = insertPrice
              result.DATE = insertDate
              result.type = 'INSERT_STOCK_POSSESS'
              await this.api.axiosGql('INSERT_STOCK_POSSESS', result)
              .then( async () => {
                this.$notify('StockPossess 테이블의 ' + insertName + ' 종목이 수정 되었습니다')
              }).catch( (err) => {
                console.log(err)
                this.$notify('수정이 되지 않았습니다')
              })
            }
          })
        } else if(stockType === 'Delete') {
          if(insertBroker === '' || insertCode === '') {
            this.$notify('누락된 값이 있습니다.')
            return
          } else {
            if(confirm(insertName +"(" + insertCode + ", " + insertBroker + ") 종목을 삭제할까요?")) {
              await this.api.axiosGql('DELETE_STOCK', {code:insertCode, broker: insertBroker})
              .then( (result) => {
                const json = result.data
                if(json !== undefined && json.length !== 0 ) {
                  this.$notify('삭제 되었습니다(' + json.NAME + ')')
                } else{
                  this.$notify('삭제된 종목이 없습니다.')
                }
              }).catch( (err) => {
                console.log(err)
                this.$notify('다시 시도해주세요.')
              })      
            }
          }
        }
      } else if(stockType === '조회') {
        this.stockPage = 1
        this.stockSearch(this.stockPage, false)
        this.collapseAll()
      } else if(stockType === '수정') {
        this.stockSearch(this.stockPage, false)
        this.collapseAll()
      } else if(stockType == 'EXECUTE_FORCE') this.api.axiosGql(stockType, {type:stockType, code:'', broker:''})
      else if(stockType == 'SELECT_ALL_STOCK_PROFIT') this.getAllStockProfit()
      else if(stockType == 'cursor') this.$refs.stock_input.value = ''
      // } else if(stockType === 'TARGET_SELL_STOCKS')  {
      //   console.log(stockType)
      // } else if(stockType === 'TARGET_BUY_STOCKS')  {
      //   console.log(stockType)
      // }
    },
    async getRecentTrade(origin) {
      for(let i=0; i<origin.length; i++) {
        let stock = origin[i]
        let code = stock.CODE
        let broker = stock.BROKER
        // api call GET_TOP_STOCK_BY_CODE
        await this.api.axiosGql('SELECT_TOP_STOCK_BY_CODE', {type:'SELECT_TOP_STOCK_BY_CODE', code: code, broker: broker})
        .then( async (result2) => {
          let topStock = result2.data
          if(topStock.length > 0) {
            stock.DATE = topStock[0].DATE
            stock.IS_SELL = Boolean(topStock[0].IS_SELL)
            stock.INSERT_AMOUNT = topStock[0].AMOUNT
            stock.RECENT_PRICE = topStock[0].PRICE
            origin[i] = stock
          }
        })
      }
      return origin
    },
    async stockSearch(page, isPage) {
      let selectedValue = this.$refs.stock_input.value
      let selectedMenu  = this.$refs.condition.value
      let isUpdn        = Boolean(this.$refs.chk_updn.checked)
      let isDesc        = Boolean(this.$refs.chk_desc.checked)
      let arrange       = Boolean(this.$refs.chk_zero.checked)
      let isNotice      = Boolean(this.$refs.chk_notice.checked)
    if(selectedMenu === 'opt0') {
        arrange === true ? arrange = -2 : arrange = -3    // -2 : 0주 포함
        let selectedBroker = this.$refs.select_broker.value
        let origin
        this.stockPage = 1
        await this.api.axiosGql('SELECT_STOCK_BY_CONDITION', {type:'SELECT_STOCK_BY_CONDITION', code: selectedValue, broker:selectedBroker, page: this.stockPage, isNext:arrange, isUpdn: isUpdn, isNotice: isNotice, isDesc: isDesc})
        .then( async (result) => {
          origin = result.data
          if(origin === undefined || origin.length === 0) {
            this.$notify('종목이 없습니다')
            if(this.stockPage === 1) this.totalCnt = 0
            if(isPage) this.stockPage -= 1
          } else {
            this.stockList = []
            this.stockcKey++
            await this.getRecentTrade(origin)
            .then( async (tmpResult) => {
              this.stockList = tmpResult
              this.totalCnt = this.stockList.length
              if(!isPage) this.stockPage = 1
            })
          }
        })
      } else if(selectedMenu === 'opt1') {
        arrange === true ? arrange = -1 : arrange = 0
        let selectedBroker = this.$refs.select_broker.value
        let origin
        await this.api.axiosGql('SELECT_STOCK_BY_CONDITION', {type:'SELECT_STOCK_BY_CONDITION', code: selectedValue, broker:selectedBroker, page: page, isNext:arrange, isUpdn: isUpdn, isNotice: isNotice, isDesc: isDesc})
        .then( async (result) => {
          origin = result.data
          if(origin === null || origin === undefined || origin.length === 0) {
            this.$notify('종목이 없습니다')
            if(this.stockPage === 1) this.totalCnt = 0
            if(isPage) this.stockPage -= 1
          } else {
            this.stockList = []
            await this.getRecentTrade(origin)
            .then( async (tmpResult) => {
              this.stockcKey++
              this.stockList = tmpResult
              this.totalCnt = this.stockList.length
              if(!isPage) this.stockPage = 1
            })
          }
        })
      } else if(selectedMenu === 'opt2') {
        await this.api.axiosGql('SELECT_POSSESS_LIST', {type:'SELECT_POSSESS_LIST', isNext:-1, page:1, isNotice: isNotice})
        .then( async (result) => {
          let origin = result.data
          if(origin === undefined || origin.length === 0) {
            this.$notify('종목이 없습니다')
            if(this.stockPage === 1) this.totalCnt = 0
            if(isPage) this.stockPage -= 1
          } else {
            let resMap = new Map()
            origin.map( (stock) => {
              let tmpList
              if(resMap.has(stock.CODE)) {
                tmpList = resMap.get(stock.CODE)
                tmpList.push(stock)
              } else tmpList = [stock]
              resMap.set(stock.CODE, tmpList)
            })
            let result = Array()
            let dealType
            resMap.forEach( (value, code) => {
              value.map( (stock) => {
                if(stock.BROKER === '한국투자증권') {
                  let sameCodeStockList = resMap.get(code)
                  let hantooAmount = Number(stock.AMOUNT)                   // (한투)보유량
                  // let hantooName = stock.NAME                            // (한투)종목명
                  let hantooProfitRate = Number(stock.PROFIT_RATE)          // (한투)수익률
                  // let hantooTypeColor = stock.TYPE_COLOR                 // (한투)목표매매가 도달여부(blue/red)
                  let hantooPresPrice = Number(stock.PRES_PRICE)            // (한투)현재가
                  let hantooNextBuyPrice = Number(stock.NEXT_BUY_PRICE)     // (한투)목표매수가
                  // let hantooNextSellPrice = stock.NEXT_SELL_PRICE        // (한투)목표매도가
                  // let hantooAvgPrice = stock.PRICE                       // (한투)평균단가
                  // let hantooDate = stock.DATE                            // (한투)최근매매일
                  
                  if(isUpdn === true) { 
                    dealType = "▶ 목표 매도가 도달 ◀"
                    if( hantooAmount > 0 && hantooProfitRate >= 2.0 ) {     // 한투 종목이 목표매매가 도달
                      result.push(stock)
                    } else if(hantooAmount === 0) {                         // 한투 매도한 경우
                      sameCodeStockList.map( (stock2) => {
                        if(stock2.BROKER !== '한국투자증권' && Number(stock2.PROFIT_RATE) >= 25.0 && Number(stock2.AMOUNT) > 0 && Number(stock2.NEXT_SELL_PRICE) <= Number(stock2.PRES_PRICE)) {
                          result.push(stock2)
                        }
                      })
                    }
                  } else {
                    dealType = "▶ 목표 매수가 도달 ◀"
                    if( hantooProfitRate <= -20.0 && hantooPresPrice <= hantooNextBuyPrice) {
                      result.push(stock)
                    } 
                    if(hantooAmount === 0) {
                      sameCodeStockList.map( async (stock2) => {
                        if(stock2.BROKER !== '한국투자증권' && Number(stock2.PROFIT_RATE) <= -20.0 && Number(stock2.NEXT_BUY_PRICE) >= Number(stock2.PRES_PRICE) ) {
                          await this.api.axiosGql('presPrice', {type: 'presPrice', code: stock2.CODE})
                          .then( async (result2) => {
                            let presPrice = Number(result2.data)
                            if(presPrice < hantooNextBuyPrice) result.push(stock2)
                          })
                        }
                      })
                    }
                  }
                  return
                }
              })
            })

            let msg = dealType + ' \n' 
            result.map( (stock) => {
              msg += stock.NAME + '/' +
              stock.CODE + '/' + 
              stock.BROKER + '\n'
            })
            console.log(msg)
            // this.api.axiosGql('SLACK', {type: 'SLACK', msg: msg})
            this.stockList = []
            this.stockcKey++
            await this.getRecentTrade(result)
            .then( async (tmpResult) => {
              this.stockList = tmpResult
              this.totalCnt = this.stockList.length
              if(!isPage) this.stockPage = 1
            })
          }
        })
      } else if(selectedMenu === 'opt3') {
        await this.api.axiosGql('SELECT_RECENT_SELL_STOCK', {type: 'SELECT_RECENT_SELL_STOCK', isNotice:isNotice})
        .then( async (result) => {
          let origin = result.data
          if(origin === null || origin === undefined || origin.length === 0) {
            this.$notify('종목이 없습니다')
            if(this.stockPage === 1) this.totalCnt = 0
            if(isPage) this.stockPage -= 1
          } else {
            this.stockList = []
            this.stockcKey++
            await this.getRecentTrade(origin).then( async (tmpResult) => {
              this.stockList = tmpResult
              this.totalCnt = this.stockList.length
              if(!isPage) this.stockPage = 1
            })
          }
        })
      } else if(selectedMenu === 'opt4') {
        await this.api.axiosGql('SELECT_STOCK_BY_DATE', {type: 'SELECT_STOCK_BY_DATE', page: page, date: selectedValue})
        .then( async (result) => {
          let origin = result.data
          if(origin === undefined || origin.length === 0) {
            this.$notify('종목이 없습니다')
            if(this.stockPage === 1) this.totalCnt = 0
            if(isPage) this.stockPage -= 1
          } else {
            this.stockList = []
            this.stockList = origin
            this.totalCnt = this.stockList.length
            if(!isPage) this.stockPage = 1
            this.stockcKey++
          }
        })
      } else if(selectedMenu === 'opt5') {
        await this.api.axiosGql('SELECT_STOCK_TRADE', {type:'SELECT_STOCK_TRADE', page: -1, cnt: -1, code: selectedValue})
        .then( async (result) => {
          let json = result.data
          
          if(json === undefined || json.length === 0) {
            this.$notify('종목이 없습니다')
            if(this.stockPage === 1) this.totalCnt = 0
          } else {
            this.stockList = []
            this.stockcKey++
            json.map( async (stock) => {
              await this.api.axiosGql('presPrice', {type: 'presPrice', code: stock.CODE})
              .then( async (result) => {
                let presPrice = Number(result.data)
                let price = stock.PRICE
                let isSell = Boolean(stock.IS_SELL)
                
                if(isUpdn === true) {
                  if(isSell === true) {
                    if(presPrice >= price) this.stockList.push(stock)
                  } else { 
                    if(presPrice <= price) this.stockList.push(stock)
                  }
                } else {
                  this.stockList = json
                  this.totalCnt = this.stockList.length
                }
              })
            })
          }
        })
      } else if(selectedMenu === 'opt6') {
        arrange === true ? arrange = -1 : arrange = 0
        await this.api.axiosGql('SELECT_ETF_LIST', {type: 'SELECT_ETF_LIST', isNext:arrange })
        .then( async (result) => {
          let origin = result.data
          if(origin === undefined || origin.length === 0) {
            this.$notify('종목이 없습니다')
            if(this.stockPage === 1) this.totalCnt = 0
          } else {
            await this.getRecentTrade(origin)
            .then( async (tmpResult) => {
              this.stockList = []
              this.stockcKey++
              this.stockList = tmpResult
              this.totalCnt = this.stockList.length
            })
          }
        })
      } else if(selectedMenu === 'opt7') {
        const code = this.$refs.stock_code.value
        isUpdn = Boolean(this.$refs.chk_updn.checked)
        isDesc = Boolean(this.$refs.chk_desc.checked)
        isNotice = Boolean(this.$refs.chk_notice.checked)
        arrange = Boolean(this.$refs.chk_zero.checked)
        arrange === true ? arrange = -1 : arrange = 0
        await this.api.axiosGql('SELECT_SAMSUNG_ONLY', {type:'SELECT_SAMSUNG_ONLY', code: code, page:page, isUpdn: isUpdn, isDesc: isDesc, isNotice: isNotice, amount:arrange})
        .then( async (result) => {
          let origin = result.data 
          if(origin === undefined || origin.length === 0) {
            this.$notify('종목이 없습니다')
            if(this.stockPage === 1) this.totalCnt = 0
            if(isPage) this.stockPage -= 1
          } else {
            this.stockList = []
            this.stockcKey++
            await this.getRecentTrade(origin)
            .then( async (tmpResult) => {
              this.stockList = tmpResult
              this.totalCnt = this.stockList.length
            })
          }
        })
      }
      this.stockcKey++
    },
    async cleanUp() {
      await this.api.axiosGql('SELECT_STOCK_LIST', {type:'SELECT_STOCK_LIST', date: ''})
      .then( async (res) => {
        let jsonList = res.data
        let sumStock = new Map()

        jsonList.map( (stock) => {
          let name  = stock.NAME
          let code  = stock.CODE
          let price = Number(stock.PRICE)
          let broker = stock.BROKER
          let amount = Number(stock.AMOUNT)
          let isSell = Boolean(stock.IS_SELL)

          let key = code + '-' + broker
          if(sumStock.has(key)) {
            let savedStock = sumStock.get(key)
            if(isSell === true) {   // 매도
              savedStock.AMOUNT -= amount
            } else {                // 매수
              let oldTotalPrice = savedStock.PRICE * savedStock.AMOUNT                    // 기존 총액
              let newTotalPrice = price * amount                                          // 신규 총액
              let newAmount = savedStock.AMOUNT + amount                                  // 신규 수량
              savedStock.PRICE = Math.round((oldTotalPrice + newTotalPrice) / newAmount)  // 신규 평균가
              savedStock.AMOUNT = newAmount                                               // 신규 수량
            }
            sumStock.set(key, savedStock)
          } else {
            if(isSell === false) {
              sumStock.set(key, stock)
            }
            else console.log('보유량이 없는데 매도한 종목 : ' + name)
          } 
        })
        for(let [, value] of sumStock) {
          await this.api.calcNextPrice(value, -1).then(async (result) => {
            if(result === null || result === undefined) {
              console.log('등록이 되지 않았습니다')
            } else {
              result.type = 'INSERT_STOCK_POSSESS'
              await this.api.axiosGql('INSERT_STOCK_POSSESS', result)
              .then( async () => {
              }).catch( (err) => {
                console.log(err)
              })
            }
          })
        }
        this.$notify(sumStock.size + '개 종목이 등록되었습니다.')
      })
    },
    reload(flag) {
      if(flag === 'dashboard') this.dashboardKey += 1
      else if(flag === 'home') router.push({ name: flag})
    },
    tab(tab, id) {
      let i, tabcontent, tablinks;
      tabcontent = document.getElementsByClassName("tabcontent");
      for (i = 0; i < tabcontent.length; i++) {
        tabcontent[i].style.display = "none";
      }
      tablinks = document.getElementsByClassName("tablinks");
      for (i = 0; i < tablinks.length; i++) {
        tablinks[i].className = tablinks[i].className.replace(" active", "");
      }
      document.getElementById(id).style.display = "block";
      tab.currentTarget.className += " active";
    },
    async regist() {
      let dic = this.$refs.dic.value; 
      if(dic === '' ) this.$notify('사전을 선택해주세요.')
      else {
        this.startProgress()
        let param = {}
        if(dic === 'MORPHEME') this.type = 'SELECT_MORPHEME'
        else if(dic === 'STOPWORD') this.type = 'INSERT_STOPWORD'
        else if(dic === 'USERWORD') this.type = 'INSERT_USERWORD'
        param = {type:this.type, surface: this.$refs.dic_input.value}
        
        await this.api.axiosGql(this.type, param)
        .then( async (res) => {
          let retVal
          if(dic === 'MORPHEME') {
            let morpheme = res.data.MORPHEME
            if(morpheme === null || morpheme === undefined) this.$notify('키워드가 없는 문장입니다.')
            else {
              this.type = 'INSERT_SENTENCE'
              param = {type:this.type, surface: this.$refs.dic_input.value, morpheme: morpheme}
              await this.api.axiosGql(this.type, param)
              .then( async (res) => {
                if(res === null || res === undefined) this.$notify('등록이 되지 않았습니다.')
                else this.$notify('등록되었습니다(' + morpheme + ')')
              }).catch( (err) => {
                console.log(err)
              }) 
            } 
          } else if(dic === 'STOPWORD') {
            retVal = res.data
            if(retVal === null || retVal === undefined) this.$notify('등록이 되지 않았습니다.')
            else this.$notify('등록되었습니다')
          } else if(dic === 'USERWORD') {
            retVal = res.data
            if(retVal === null || retVal === undefined) this.$notify('등록이 되지 않았습니다.')
            else this.$notify('등록되었습니다')
          }
        }).catch( (err) => {
          console.log(err)
        }).finally( () => { 
          this.stopProgress() 
          document.getElementById('progress').style.display = 'none'
          this.$refs.regist.disabled = false
        })
      }
    },
    async search(page, dicType) {
      let dic
      if(dicType.length > 1) dic = dicType
      else dic = this.$refs.dic.value

      if(dic === '' ) this.$notify('사전을 선택해주세요.')
      else {
        this.startProgress()
        let type = ''
        let param = {}
        if(dic === 'MORPHEME') type = 'SELECT_SENTENCE'
        else if(dic === 'STOPWORD') type = 'SELECT_STOPWORD'
        else if(dic === 'USERWORD') type = 'SELECT_USERWORD'
        
        param = {type:type, surface: this.$refs.dic_input.value, page: page}
        
        await this.api.axiosGql(type, param)
        .then( async (res) => {
          if(dic === 'MORPHEME') items.value = res.data
          else if(dic === 'STOPWORD') items.value = res.data
          else if(dic === 'USERWORD') items.value = res.data
        }).catch( (err) => {
          console.log(err)
        }).finally( () => { 
          this.stopProgress() 
          document.getElementById('progress').style.display = 'none'
          this.$refs.regist.disabled = false
        })
      }
    },  
    async modify(param) {
      param.type = "UPDATE_POSSESS"
      await this.api.axiosGql("UPDATE_POSSESS", param)
      this.emitter.emit('confirm', {message: '수정되었습니다.', header:'가입', icon:'pi pi-info-circle'})
    },
    startProgress() {
      this.$refs.regist.disabled = true
      this.auto_reload_func = setInterval(() => {
        this.per += 1
      }, 3)
    },
    stopProgress() {
      clearInterval(this.auto_reload_func)
      this.$refs.regist.disabled = false
    },
    async selected(type) {
      // this.visibleSelect = type
      // document.getElementsByClassName('search_select')[0].style.display='none'    // BROKER
      // document.getElementsByClassName('search_select')[1].style.display='none'    // DAY
      // document.getElementsByClassName('search_select')[2].style.display='none'    // RANGE
      
      if(type === '') {
        this.$refs.dic_input.value = ''
        this.$refs.dic_input.placeholder = this.$refs.dic.selectedOptions[0].innerText
      // } else if(type === 'opt1' || type === 'opt2' || type === 'opt3' || type === 'trade' || type === 'etf') {
      //   this.$refs.stock_input.value = ''
      //   this.$refs.stock_input.placeholder = '종목코드'
      //   document.getElementsByClassName('search_select')[0].style.display=''
      // } else if(type === 'DAY') {
      //   this.$refs.stock_input.placeholder = 'YYYYMMDD'
      //   let ms = Number(Date.parse(Date())) / 1000
      //   this.$refs.stock_input.value = this.api.getDate(ms, 'YYYYMMDD')
      //   document.getElementsByClassName('search_select')[1].style.display=''
      // } else if(type === 'RANGE') {
      //   this.$refs.stock_input.value = ''
      //   document.getElementsByClassName('search_select')[2].style.display=''
      // } else if(type === 'NAME') {
      //   this.$refs.stock_input.value = ''
      //   this.$refs.stock_input.placeholder = '종목코드'
      //   document.getElementsByClassName('search_select')[0].style.display=''
      }
    },
    async paging(type) {
      const condition = this.$refs.condition.value
      if(condition === 'opt2' || condition === 'opt6' || condition === 'opt5' || condition === 'opt3') {
        this.$notify('페이지 조회가 불가능합니다.')
      } else {
        if(type === 'prev') {
          if(this.stockPage === 1) {
            this.$notify('첫 페이지입니다.')
            return
          } else this.stockPage -= 1
        } else this.stockPage += 1
        this.stockSearch(this.stockPage, true)
      }
    },
    setRecentInfo(params) {
      let infoFontList = document.getElementsByClassName('infoFont')
      let idx = Number(params.idx)
      infoFontList[idx].innerHTML = params.presPrice.toLocaleString() + '원 &nbsp;| &nbsp;' + params.profitRate + '%'
    },
    // setSubInfo(isSell) {
    //   console.log(isSell)
    //   let subInfoFontList = document.querySelectorAll('.subInfoFont')
    //   console.log(subInfoFontList)
    // },
  }
}
</script>

<style>
.padding-left {
  padding-left:10px;
}
#select {
  width: 100%;
  min-width: 100px;
  text-align: center;
}
.essentialy {
  border:3px solid red;
}
.collapsible {
  background-color: lightgoldenrodyellow;
  color: black;
  cursor: pointer;
  margin-right: 10px;
  margin-bottom: 5px;
  padding: 5px;
  border-radius: 9px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
}
.content {
  max-height: 0;
  height: auto;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  background-color: rgb(250, 250, 250);
}
.condition-admin {
  margin-right: 5px;
}
</style>